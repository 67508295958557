import React,{useEffect} from 'react';
import styles from "./Notifications.module.css";
import {Link} from "react-router-dom";
import { notification_unread_count } from '../../../actions/notification';
import { useDispatch,useSelector } from 'react-redux';
import notification_icon from "./../../../images/notification_icon.png"
function Notifications() {
  const dispatch=useDispatch()
  const user=useSelector((state) => state.layout.profile)
  const count=useSelector((state)=>state.merchandise.notifications_count?.data)
  useEffect(()=>{
    const data=new FormData()
    // data.append("id",user?.id)
    // dispatch(notification_unread_count(data))
  },[user])

  return (
    <>
      
      <Link to="/Notifications/Notifications" className={styles.Notifications}><img src={notification_icon} alt="Notifications" height={"25px"} style={{marginRight:"5px"}} /><span className={styles.num} >{count}</span></Link>
      
    </>
  );
}

export default Notifications;
