import React from 'react';
import { useState,useMemo } from 'react';
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import styles from './../../styles/PageTitle.module.css'
import ListTable from '../utils/Table'
export default function MoviesRented() {
  const location=useLocation()
    const [tableData,setTableData]=useState({
        tableTitle:"Movies Rented",
        disableDelete:true,
        tableHead:[
           
              {
                id: 'name',
                label: 'Movie/Episode Name',
              },
              {
                id: 'watch_hours',
                label: 'Watchhours',
              },
              {
                id: 'created_at',
                label: 'Last Watched on',
              },
              {
                id: 'user',
                label: 'Watched By',
              },
              {
                id: 'device_name',
                label: 'Device Name',
              },
              // {
              //   id: 'location',
              //   label: 'Location',
              // },
             
        ],
        tableBody:[
        
        ]})
    const [form,setForm]=useState({})
    const [isEdit,setIsEdit]=useState(false)
    useMemo(()=>{
      const temp = tableData
      temp.tableBody=location.state?.data||[]
      setTableData({...temp})
    },[location.state?.data])
  return (
    <>
 


      
                 <ListTable tableData={tableData} key={"ListTable"} setForm={setForm} setTableData={setTableData} setIsEdit={setIsEdit}/>

     
    </>
  );
}
