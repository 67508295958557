import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Export from "../utils/Export";
import {
  customer_delete,
  customer_update,
  all_customer_list,
} from "./../../actions/customer";
import { all_subscription_list } from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
export default function Customer() {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({
    tableTitle: "Customers",
    deleteRecord: customer_delete,
    updateRecord: customer_update,
    onDeleteText: "Are you sure want to delete?",
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure you want to activate this customer?",
    onInactiveText: [
      "Are you sure you want to inactivate this customer? ",
      "The consumer will no longer be logged into the system once you inactivate it.",
    ],
    tableHead: [
      {
        id: "name",
        label: "Name",
        link: "/Customer/CustomerDetail/CustomerDetail",
        color: "var(--gradientColor1)",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "mobile",
        label: "Mobile No",
      },
      {
        id: "socialType1",
        label: "Login By",
      },
      {
        id: "deviceType",
        label: "Device",
        subText: "deviceId",
      },
      {
      	id: "subscription1",
      	label: "Subscription",
      },
      {
        id: "created_at",
        label: "Registered On",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
    tableBody: [],
    filterColumn: [
      {
        id: "1",
        title: "Plan Type",
        name: "subscription1",
        options: ["Silver", "Gold", "Platinum" , "free"],
      },
      {
        id: "1",
        title: "Login By",
        name: "socialType1",
        options: ["Gmail", "Mobile"],
      },
    ],
    isDateRangeFilter: "created_at",
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const customers = useSelector((state) => state.customers.customers);
  // const subscriptions = useSelector((state) => state.subscriptions.subscriptions);

  useEffect(() => {
    // dispatch(all_subscription_list())
    dispatch(all_customer_list({id:0}));
  }, []);
  useMemo(() => {
    if (customers?.statuscode == 200 && tableData.tableBody[tableData.tableBody.length - 1]?.id!=customers?.data[customers?.data.length - 1]?.id) {
      const temp = tableData;
      temp.tableBody = temp.tableBody.concat(customers?.data.map((ele) => ({
        ...ele,
        name: ele?.firstName + " " + ele?.lastName,
        subscription:
          ele?.subscription !== null ? ele?.subscription?.plan_name : " - ",
        subscription1:
          ele?.subscription === null ? "free" : ele?.subscription?.plan_name ,
        mobile: ele?.mobileNumber !== null ? ele?.mobileNumber : " - ",
        socialType1: ele?.socialType !== null ? "Gmail" : "Mobile",
      })));
      setTableData({ ...temp });
      if(customers?.isFull=="False" ){
				
				
				dispatch(all_customer_list({id:customers?.data[customers?.data.length - 1]?.id}))
			
			}
    }
  }, [customers]);
  // useMemo(()=>{

  // 	// if(subscriptions?.statuscode==200){const temp=tableData
  // 	// temp.filterColumn[0]["options"]=subscriptions?.data.map((ele)=>(ele?.plan_name))
  // 	// setTableData({...temp})}
  //   },[subscriptions])

  return (
    <>
      <Export
        fileName={"Customers"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
      />

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
