import React from "react";
import { useState, useMemo,useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import { genre_create,genre_delete,genre_update,all_genre_list } from "../../../actions/Masters/genre";
import { useDispatch,useSelector } from 'react-redux';

import { useLocation } from "react-router-dom";
export default function Genre() {
	const dispatch=useDispatch()
const location = useLocation();
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});
	useMemo(() => {
		if(isEdit) 
     {setView("create_new") }
    else
    {setView("view_all")
    setForm({});
  }
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
	}, [location]);
	const [tableData, setTableData] = useState({
		tableTitle: "Genre",deleteRecord:genre_delete,
		updateRecord:genre_update,
		onDeleteText:"Are you sure want to delete?",
		onUpdateText:"Are you Sure?",
		tableHead: [
			{
				id: "genre_title",
				numeric: false,
				disablePadding: true,
				label: "Genre",
			},
			{
				id: "genre_image",
				label: "Image",
        isImage:true
			},
			
			{
				id: "edit",
				label: "Update",
			},
		],
		tableBody: [
			
		],
	});
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Add Genre",
			name: "genre_title",
			maxLength:15,
regex : /^[a-zA-Z\s]+$/,
required:true
		},
	
{type: "undefined"},
		{
			id: "3",
			type: "image",
			title: "Image",
			name: "genre_image",
			subtitle:"(Resolution : 512px x 512px) *",
			subsubsubtitle:"Support only JPG,PNG,JPEG",
        subsubtitle:"Max File Size 1MB",
		},

		{
			id: "5",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];
	
    const genre = useSelector((state) => state.masters.genre);
    useEffect(()=>{
      dispatch(all_genre_list())
    },[])
    useMemo(()=>{
      
      if(genre?.statuscode==200){const temp=tableData
      temp.tableBody=genre?.data
      setTableData({...temp})}
    },[genre])


	const formTitle = isEdit ? "Edit Genre" : "Create Genre";


	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof(form?.[element.name])!="string") {
				const temp = form;
				temp["temp"+element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit)
			{
		
			dispatch(genre_update(data))
		}
		else
			{
		dispatch(genre_create(data))
		}
		
	};
	useMemo(()=>{
		if(message?.statuscode==200)
		{
			
		setIsEdit(false);
		setForm({});
		setTimeout(() => {
			dispatch(all_genre_list());
		}, 1000);
		setView("view_all");}
		else{
			
		setForm({...form,genre_image:form?.tempgenre_image });
		}
	},[message])
	return (
		<>
			
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
