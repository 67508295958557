

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Notification from '../components/Notification/Notification';
function MerchandiseModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Notifications' element={<Layout children={Notification()}/>}/>

      </Routes>
    </div>
  );
}

export default MerchandiseModule;
