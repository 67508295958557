import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import { language_create, language_delete, language_update, all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
export default function Language() {
	const dispatch = useDispatch()
	const location = useLocation();
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
	}, [location]);
	const [tableData, setTableData] = useState({
		tableTitle: "Languages", deleteRecord: language_delete,
		updateRecord: language_update,
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "language_name",
				label: "Language",
			},
			{
				id: "language_image",
				label: "Image",
				isImage: true
			},

			{
				id: "edit",
				label: "Update",
			},
		],
		tableBody: [
			{
				id: 0,
				language_name: "Movie",
				language_image: "Landscape",
				status: "Active",
				edit: 0,
			},
		],
	});
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Add Language",
			name: "language_name",
			maxLength: 15,
			regex: /^[a-zA-Z\s]+$/,
			required: true
		},

		{ type: "undefined" },
		{
			id: "3",
			type: "image",
			title: "Image",
			name: "language_image",
			subtitle: "(Resolution : 512px x 512px) *",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			subsubtitle: "Max File Size 1MB",
			required: true

		},

		{
			id: "5",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];
	const formTitle = isEdit ? "Edit Language" : "Create Language";



	const language = useSelector((state) => state.masters.languages);
	useEffect(() => {
		dispatch(all_language_list())
	}, [])
	useMemo(() => {

		if (language?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = language?.data
			setTableData({ ...temp })
		}
	}, [language])
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});


		if (isEdit) {
			dispatch(language_update(data))
		}
		else {
			dispatch(language_create(data))
		}

	};
	useMemo(() => {
		if (message?.statuscode == 200) {

			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_language_list());
			}, 1000);
			setView("view_all");
		}
		else {

			setForm({ ...form, language_image: form?.templanguage_image });
		}
	}, [message])
	return (
		<>


			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
