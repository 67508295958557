import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
	cast_create,
	cast_delete,
	cast_update,
	all_cast_list,
} from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import Import from "../../utils/Import";
import { useLocation } from "react-router-dom";
import Export from "../../utils/Export";
export default function Cast() {
	const dispatch = useDispatch();
	const location = useLocation();
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({});
		}
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
	}, [location]);
	const [tableData, setTableData] = useState({
		tableTitle: "Casts",
		deleteRecord: cast_delete,
		updateRecord: cast_update,
		onDeleteText: "Are you sure to delete?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "cast_name",
				numeric: false,
				disablePadding: true,
				label: "Cast",
			},
			{
				id: "cast_image",
				label: "Image",
				isImage: true,
			},
			{
				id: "cast_type",
				label: "Cast Type",
				isButtonDisplay: true,
			},
			{
				id: "edit",
				label: "Update",
			},
		],
		tableBody: [
			
		],
		filterColumn: [
			{
				id: "1",
				title: "Select Cast Type",
				name: "cast_type",
				options: ["Actor", "Actress", "Director", "Producer"],
			},
		],
	});

	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Cast Name",
			name: "cast_name",
			required: true,
		},
		{
			id: "2",
			type: "select",
			title: "Cast Type",
			name: "cast_type",
			options: ["Actor", "Actress", "Director", "Producer"],
			required: true,
		},

		{
			id: "3",
			type: "image",
			title: "Image",
			subtitle: "(Resolution : 512px x 512px) *",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle:"Support only JPG,PNG,JPEG",
			name: "cast_image",
			required: true,

		},

		{
			id: "5",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];

	const casts = useSelector((state) => state.masters.casts);
	useEffect(() => {
		dispatch(all_cast_list());
	}, []);
	useMemo(() => {
		if (casts?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = casts?.data;
			setTableData({ ...temp });
		}
	}, [casts]);

	const formTitle = isEdit ? "Edit Cast" : "Create Cast";
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit) {
			dispatch(cast_update(data));
		} else {
			dispatch(cast_create(data));
		}
	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_cast_list());
			}, 1000);
			setView("view_all");
		} else {
			setForm({ ...form, cast_image: form?.tempcast_image });
		}
	}, [message]);
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				import_excel={<Import isClubed={true}  />}
				export_excel={
					<Export
						fileName={"Casts"}
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						
						headings={tableData.tableHead.map((value) => value.label)}
					/>
				}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
