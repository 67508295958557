import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

const breadcrumbMap = {
  // 'Dashboard': {name:'Inbox',path:"/Dashboard/Dashboard"},
  'Movie': {name:'Movie',path:"/Movie/Movie"},
  'UpcomingMovie': {name:'Upcoming Movies',path:"/Movie/UpcomingMovie"},
  'Series': {name:'Shows',path:"/WebSeries/Series/"},
  'Episode': {name:'Episode',path:"/WebSeries/Episode/"},
  'TVCategory': {name:'Streaming Category',path:"/LiveStreaming/TVCategory"},
  'TVChannel': {name:'Channel',path:"/LiveStreaming/TVChannel"},
  'SliderBanner': {name:'Slider Banner',path:"/SliderBanner/SliderBanner"},
  'Customer': {name:'Customers',path:"/Customer/Customer"},
  'Transactions': {name:'Transactions',path:"/Transactions/Transactions/"},
  'WatchHours': {name:'Watch-hours',path:"/WatchHours/WatchHours/"},
  'Distributors': {name:'Producers',path:"/Distributors/Distributors/"},
  'DistributerProducts': {name:"Producer's Products",path:"/Distributors/DistributerProducts/"},
  'Complaint': {name:'Complaints',path:"/Complaint/Complaint/"},
  'Subscriptions': {name:'Subscriptions',path:"/Subscriptions/Subscriptions/"},
  'Coupon': {name:'Promocode',path:"/Coupon/Coupon"},
  'PromocodeHistory': {name:'Promocode History',path:"/Coupon/PromocodeHistory"},
  'Advertisers': {name:'Advertisers',path:"/Advertisers/Advertisers/"},
  'Advertisement': {name:'Advertisement',path:"/Advertisers/Advertisement/"},
  'Masters': {name:'Masters',path:"/Masters/Category/"},
  'Avatar': {name:'Avatar',path:"/Masters/Avatar/"},
  'Category': {name:'Category',path:"/Masters/Category/"},
  'SubCategory': {name:'Sub Category',path:"/Masters/SubCategory/"},
  'Language': {name:'Language',path:"/Masters/Language/"},
  'Genre': {name:'Genre',path:"/Masters/Genre/"},
  'Cast': {name:'Cast',path:"/Masters/Cast/"},
  'SubAdmin': {name:'Sub Admin',path:"/Masters/SubAdmin/"},
  'Settings': {name:'Settings',path:"/Settings/AboutUs/"},
  'AboutUs': {name:'About Us',path:"/Settings/AboutUs/"},
  'PrivacyPolicy': {name:'Privacy Policy',path:"/Settings/PrivacyPolicy/"},
  'TermsConditions': {name:'Terms & Conditions',path:"/Settings/TermsConditions/"},
  'RefundPolicy': {name:'Refund Policy',path:"/Settings/RefundPolicy/"},
  'ContactUs': {name:'Contact Us',path:"/Settings/ContactUs/"},
  'AppSetting': {name:'App Setting',path:"/Settings/AppSetting/"},
  'UserLogs': {name:'User Logs',path:"/Settings/UserLogs/"},
  'MovieDetails': {name:'Movie Details',path:"/Movie/MovieDetails"},
  'EpisodeDetails': {name:'Episode Details',path:"/WebSeries/Episode/EpisodeDetails"},
  'WebSeries': {name:'TV Shows',path:"/WebSeries/Series/"},
  'CustomerDetail': {name:'Customer Detail',path:"/Customer/CustomerDetail/CustomerDetail"},
  'LiveStreaming': {name:'Live Streaming',path:"/LiveStreaming/TVCategory"},
  'MoviesDownloaded': {name:'Movies Downloaded',path:"/Customer/CustomerDetail/MoviesDownloaded"},
  'MoviesWatched': {name:'Movies Watched',path:"/Customer/CustomerDetail/MoviesWatched"},
  'MoviesRented': {name:'Movies Rented',path:"/Customer/CustomerDetail/MoviesRented"},
  'Profile': {name:'Profile',path:"/Dashboard/Profile/"},
  // 'Layout': {name:'',path:"/Dashboard/Dashboard"},
  'Notifications': {name:'Notifications',path:"/Notifications/Notifications"},
  
};



function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function Page() {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);
  const pathnames = paths[0]=="Dashboard"?[]:[...new Set(paths)];

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <LinkRouter underline="hover" color="inherit" to="/Dashboard/Dashboard">
        Dashboard
      </LinkRouter>
      {/* {pathnames.map((value, index) => {
        const last = index >= pathnames.length - 2;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {pathnames[index]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {pathnames[index]}
          </LinkRouter>
        );
      })} */}
      {pathnames.map((ele,index)=>index!=pathnames.length-1?
      <LinkRouter underline="hover" color="inherit" to={breadcrumbMap?.[ele].path} key={breadcrumbMap?.[ele].path}>
      {breadcrumbMap?.[ele].name}
          </LinkRouter>:
          
<Typography color="text.primary" key={breadcrumbMap?.[ele].path}>
            {breadcrumbMap?.[ele].name}
          </Typography>)
          }
    </Breadcrumbs>
  );
}

export default function RouterBreadcrumbs() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (

      <Box sx={{ display: 'flex', flexDirection: 'column',ml:2 }} fontSize={"12px"}>
        <Routes>
          <Route  path="*" element={<Page />} />
        </Routes>
     
      </Box>
   
  );
}
