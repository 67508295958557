import React,{useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Features from "./elements/Features";
import RegisteredUsers from "./elements/RegisteredUsers";
import DailyRevenue from "./elements/DailyRevenue";
import TotalRevenue from "./elements/TotalRevenue";
import SubscribedUsers from "./elements/SubscribedUsers";
import HighestSubscription from "./elements/HighestSubscription";
import MostWatchedMovies from "./elements/MostWatchedMovies";
import MostWatchedSeries from "./elements/MostWatchedSeries";
import RecentlyAddedMovies from "./elements/RecentlyAddedMovies";
import RecentSubscriber from "./elements/RecentSubscriber";
import Renewal from "./elements/Renewal";
import MonthlyRevenue from "./elements/MonthlyRevenue";
import { movies_data_dashboard,count_on_dashboard,total_subscribe_user,most_watch_tv_show_dashboard,recent_subscriber_dashboard } from '../../actions/dashboard';
import { useDispatch,useSelector } from 'react-redux';

export default function Dashboard() {
  const user=useSelector((state) => state.layout.profile)
  const dispatch=useDispatch()
  const data=new FormData()
		data.append('id',user?.id)
  useEffect(()=>{
		
    dispatch(movies_data_dashboard(data))
    dispatch(count_on_dashboard(data))
    dispatch(total_subscribe_user(data))
    dispatch(most_watch_tv_show_dashboard(data))
    dispatch(recent_subscriber_dashboard(data))
	},[user])
	const registeredUsers = useSelector((state) => state.dashboard.totalusers);
  const dashboardcount = useSelector((state) => state.dashboard.dashboardcount?.data);
  const recently_added_data = useSelector((state) => state.dashboard.dashboardmovies?.recently_added_data);
  const most_watch_movies = useSelector((state) => state.dashboard.dashboardmovies?.most_watch_movies);
  
  const monthlyrevenue = useSelector((state) => state.dashboard?.monthlyrevenue);
  const tv_show_dashboard = useSelector((state) => state.dashboard?.tv_show_dashboard);
  const recentSubscriberDashboard= useSelector((state) => state.dashboard?.recent_subscriber_dashboard)
  const recentSubscriberDashboard1= useSelector((state) => state.dashboard);
  console.log(recentSubscriberDashboard1,"8456321")
    return (
    <>
   
   <RegisteredUsers registeredUsers={registeredUsers}/>
   
   
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} md={12} lg={12} xl={8}>
   
        <Features dashboardcount={dashboardcount} />

          

          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={12}>
             <MonthlyRevenue monthlyrevenue={monthlyrevenue}/>
            </Grid>

        
            {/* <Grid item xs={12} md={12}>
        
              <TotalRevenue />
            </Grid> */}
            
            {/* <Grid item xs={12} md={12}>
        
             <Renewal />
      </Grid> */}
      <Grid item xs={12} md={12}>
        
      <RecentSubscriber recentSubscriberDashboard={recentSubscriberDashboard}/>
      </Grid>
      <Grid item xs={12} md={12}>
        
      <RecentlyAddedMovies recently_added_data={recently_added_data}/>
      </Grid>
      
     
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={4}>
        

        {/* <SubscribedUsers /> */}
         

        <DailyRevenue />
       
        {/* <HighestSubscription /> */}
         <MostWatchedMovies most_watch_movies={most_watch_movies}/>
          <MostWatchedSeries tv_show_dashboard={tv_show_dashboard}/>
    
        </Grid>
      </Grid>
      
     
      
       
     
    </>
  );
}
