import React from "react";
import { useState, useMemo, useEffect } from "react";

import Form from "../utils/Form";
import { terms_and_conditions,terms_and_conditions_update } from "../../actions/Setting/terms_conditions";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom"
export default function TermsConditions() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [form, setForm] = useState({});
	useEffect(()=>{
		dispatch(terms_and_conditions())
	},[])
	const termsconditions=useSelector((state)=>state.setting?.termsconditions?.data[0])
	console.log("termsconditions",termsconditions)
	useMemo(()=>{
		setForm({...termsconditions})
	},[termsconditions])
	

	const formStructure= [
		{
			id: "1",
			type: "description",
			name:"description",
			title: "",
			limit:"10000"
		},

		
		{
			id: "8",
			type: "button",
			title: "Update" ,
		},
	]

	
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		
		
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		
		
			
		dispatch(terms_and_conditions_update(data));
		
		navigate("/Dashboard/Dashboard")
	};

	return (
		<>
			
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={"Terms & Conditions"}
					key={"Form"}
					setForm={setForm}
					form={form}
				/>
		
		</>
	);
}
