import logo from './logo.svg';
import './App.css';
import './styles/remixicon.css'
import 'react-tabs/style/react-tabs.css';
import "swiper/css";
import "swiper/css/bundle";

// Chat Styles
import './styles/chat.css'
// Globals Styles
import './styles/globals.css'
// Rtl Styles
import './styles/rtl.css'
// Dark Mode Styles
import './styles/dark.css'
// Left Sidebar Dark Mode Styles
import './styles/leftSidebarDark.css'
// Theme Styles
import theme from './styles/theme'

import { ThemeProvider, CssBaseline } from "@mui/material";
import {  Routes, Route } from 'react-router-dom';
import Authentication from './modules/AuthenticationModule';
import Layout from './modules/LayoutModule';
import Dashboard from './modules/DashboardModule'
import Masters from './modules/MastersModule'
import Subscriptions from './modules/SubscriptionsModule';
import Customer from './modules/CustomerModule';
import WatchHours from "./modules/WatchhoursModule"
import Transaction from "./modules/TransactionModule"
import Complaint from "./modules/ComplaintModule"
import Notification from './modules/NotificationModule';
import HomeSlider from './modules/HomeSliderModule';
import Movie from './modules/MoviesModule';
import WebSeries from './modules/WebSeriesModule';

import Settings from "./modules/SettingModule"
import { login } from "./actions/authenticate";
import { useDispatch,useSelector } from "react-redux";
import {useNavigate,useLocation} from "react-router-dom"
import { useEffect } from 'react';
import { PROFILE,
  LOGGEDIN } from './constants/actionTypes';
function App() {
  const isLoggedIn=useSelector((state) => state.layout.loggedin)
  const location=useLocation()
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const data=JSON.parse(localStorage.getItem("loggedInDetails")==undefined?"{}":localStorage.getItem("loggedInDetails"))
useEffect (() => {
   if(JSON.parse(localStorage.getItem("remember_me"))==true&&location.pathname=="/")
   { dispatch(login(JSON.parse(localStorage.getItem("loginDetails")),navigate))}
   else if(Object.keys(data).length !== 0)
   { 
    
    dispatch({ type: PROFILE, payload:data?.id });
        dispatch({ type: LOGGEDIN, payload:true });
  }
   else
   {
   
        localStorage.setItem("loggedInDetails","{}")
   }
  
  },[])
  // useEffect(() => {
    
  //   const handleContextMenu = (e) => {
   
  //     e.preventDefault()
  //   }

  
  //   document.addEventListener("contextmenu", handleContextMenu)

 
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu)
  //   }
  // }, [])
  // document.onkeydown = function(e) {
  //   if(e.keyCode == 123) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
  //      return false;
  //   }
  // }
  return (
    <div className="App">
<ThemeProvider theme={theme}>
        <CssBaseline />
        
<Routes>
  
< Route path='/Authentication/*' element={<Authentication key="Authentication"/>}/>
< Route path='/Layout/*' element={<Layout key="Layout"/>}/>
< Route path='/Dashboard/*' element={<Dashboard key="Dashboard"/>}/>
< Route path='/Masters/*' element={<Masters key="Masters"/>}/>
< Route path='/Customer/*' element={<Customer key="Customer"/>}/>
< Route path='/Subscriptions/*' element={<Subscriptions key="Subscriptions"/>}/>
< Route path='/WatchHours/*' element={<WatchHours key="WatchHours"/>}/>
< Route path='/Complaint/*' element={<Complaint key="Complaint"/>}/>
< Route path='/Transactions/*' element={<Transaction key="Transaction"/>}/>
< Route path='/Notifications/*' element={<Notification key="Notification"/>}/>
< Route path='/SliderBanner/*' element={<HomeSlider key="HomeSlider"/>}/>
< Route path='/Movie/*' element={<Movie key="Movie"/>}/>
< Route path='/WebSeries/*' element={<WebSeries key="WebSeries"/>}/>
< Route path='/Settings/*' element={<Settings key="Settings"/>}/>
< Route path='/*' element={<Authentication key="Authentication"/>}/>
      </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
