import React from "react";
import { useState, useMemo,useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import { subscription_create,subscription_delete,subscription_update,all_subscription_list } from '../../actions/subscription';
import { useDispatch,useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
export default function Subscription() {
	const dispatch=useDispatch()
	const location = useLocation();
	const [form, setForm] = useState({});
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	useMemo(() => {
		if(isEdit) 
     {setView("create_new") }
    else
    {setView("view_all")
    setForm({});
  }
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
	}, [location]);
	const [tableData, setTableData] = useState({
		tableTitle: "Subscriptions",
		updateRecord:subscription_update,
		disableDelete:true,
		onUpdateText:"Are you Sure?",
		tableHead: [
			{
				id: "plan_name",
				label: "Plan Name",
			},
			{
				id: "duration",
				label: "Duration",
			},
			{
				id: "price",
				label: "Price",
			},
		
			
			{
				id: "status",
				label: "Status",
			},
			{
				id: "edit",
				label: "Update",
			},
		],
		tableBody: [
			
		],
	});
	
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Plan Name",
			name: "plan_name",
			maxLength:"100",
			// regex : /^[a-zA-Z\s]+$/,
		},
		{
			id: "2",
			type: "select",
			title: "Select Duration",
			name: "duration",
			options: ["Yearly", "Monthly","Semi Quaterly","Quaterly"],
		},
		{
			id: "3",
			type: "inputBox",
			regex: /^[0-9\.]+$/,
			maxLength: "6",
			title: "Original Prices",
			name: "original_amount",
		  },
		{
			id: "3",
			type: "inputBox",
			regex:/^[0-9\.]+$/,
    		maxLength:"6",
			title: "Price",
			name: "price",
		},
      
	
        {
			id: "5",
			type: "description",
			title: "Description",
			name: "description",
		},
		
		{
			id: "7",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];


	
    const subscriptions = useSelector((state) => state.subscriptions.subscriptions);
	console.log(subscriptions,"658658")
    useEffect(()=>{
      dispatch(all_subscription_list())
    },[])
    useMemo(()=>{
      
      if(subscriptions?.statuscode==200){const temp=tableData
      temp.tableBody=subscriptions?.data.map((ele)=>({...ele,price: parseFloat(ele.price).toFixed(2)}))
      setTableData({...temp})}
    },[subscriptions])

	const formTitle = isEdit ? "Edit Subscription" : "Create Subscription";
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
		  if (element.type == "image" && form?.[element.name] && typeof(form?.[element.name])!="string") {
			const temp = form;
			temp["temp"+element.name] = form?.[element.name];
			temp[element.name] = URL.createObjectURL(form?.[element.name]);
	
			setForm({
			  ...temp,
			});
		  }
		});
	
		if (isEdit)
		  {
		dispatch(subscription_update(data))
		}
		else
		 { 
		dispatch(subscription_create(data))
		}
		
	  };
	  useMemo(()=>{
		if(message?.statuscode==200)
		{
			
		setIsEdit(false);
		setForm({});
		setTimeout(() => {
			dispatch(all_subscription_list());
		}, 1000);
		setView("view_all");}
		else{
			
		setForm({...form });
		}
	},[message])
	return (
		<>
			
		

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					handleSubmit={handleSubmit}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
