import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
	series_create,
	series_delete,
	series_update,
	all_series_list,
} from "../../../actions/WebSeries/series";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Export from "../../utils/Export";
export default function Series() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch();
	const location = useLocation();
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	const [tableData, setTableData] = useState({
		...TableData(),
		deleteRecord: series_delete,
		updateRecord: series_update,
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
	});

	const [form, setForm] = useState({});
	const [formStructure, setFormStructure] = useState(FormStructure());

	const [formTitle, setFormTitle] = useState("Series Details");
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({
				
				series_ownership:"Admin"
			});
		}
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({
			series_ownership:"Admin"
		});
		
	}, [location]);

	const series = useSelector((state) => state.webseries.series);
	useEffect(() => {
		const data = new FormData();
		data.append("id", user?.id);

		dispatch(all_series_list(data));
	}, [user]);

	useMemo(() => {
		if (series?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = series?.data.map((ele) => ({
				...ele,
				language: ele?.language?.language_name,
				genre: ele?.genre.map((value) => value?.genre_title),
			}));
			
			setTableData({ ...temp });
		}
	}, [series]);

	useEffect(() => {
		dispatch(all_genre_list());

		dispatch(all_language_list());
	}, []);
	const genre = useSelector((state) => state.masters.genre);
	const language = useSelector((state) => state.masters.languages);
	useMemo(() => {
		if (genre?.statuscode == 200) {
			const temp = formStructure;
			temp[3]["options"] = genre?.data.map((genre) => genre?.genre_title);

			setFormStructure([...temp]);
		}
	}, [genre]);
	useMemo(() => {
		if (language?.statuscode == 200) {
			const temp = formStructure;
			temp[2]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setFormStructure([...temp]);
		}
		if (language?.statuscode == 200) {
			const tempTableData = tableData;
			tempTableData.filterColumn[1]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setTableData({ ...tempTableData });
		}
	}, [language]);
	

	useMemo(() => {
		if (form?.series_type == "TVOD") {
			const temp = formStructure;
			temp[5]["display"] = "block";
			temp[5]["required"] = true;
			setFormStructure([...temp]);
		} else {
			const temp = formStructure;
			temp[5]["display"] = "none";
			temp[5]["required"] = false;
			setFormStructure([...temp]);
		}
	}, [form?.series_type]);


	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const temp_movie_category = form;
		temp_movie_category["tempgenre"] = form?.["genre"];
		temp_movie_category["templanguage"] = form?.["language"];
	
		temp_movie_category["genre"] = genre?.data
			.map(
				(option) => form?.["genre"]?.includes(option.genre_title) && option.id
			)
			.filter((e) => e);
		temp_movie_category["language"] = language?.data
			.map(
				(option) =>
					form?.["language"]?.includes(option.language_name) && option.id
			)
			.filter((e) => e);
		
			temp_movie_category["created_by"]=user?.id
		setForm({
			...temp_movie_category,
		});

		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit) {
			dispatch(series_update(data));
		} else {
			dispatch(series_create(data));
		}
	};
	useMemo(() => {
		if (message?.statuscode == 200) {
		
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				const data = new FormData();
				data.append("id", user?.id);

				dispatch(all_series_list(data));
			}, 1000);
			setView("view_all");
		} else {
			const tempForm = form;
			tempForm["series_thumbnail"] = form?.tempseries_thumbnail;
			tempForm["series_poster"] = form?.tempseries_poster;
			tempForm["language"] = form?.templanguage;
			tempForm["genre"] = form?.tempgenre;
			setForm({ ...tempForm });
		}
	}, [message]);
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				export_excel={
					<Export
						fileName={"TV Shows"}
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						headings={tableData.tableHead.map((value) => value.label)}
					/>
				}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
