import React from "react";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { category_create,category_update,category_delete,all_category_list } from "./../../../actions/Masters/category";
import { useSelector } from "react-redux";
import ViewChange from "../../utils/ViewChange";
import { useDispatch } from "react-redux";
import { TableData } from "./TableData";
import { formStructure } from "./FormStructure";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function Category() {
	const dispatch = useDispatch();
const location = useLocation();
const [tableData,setTableData]=useState({...TableData(),deleteRecord:category_delete,updateRecord:category_update,
	onDeleteText:"Are you sure to delete?",
	onUpdateText:"Videos are associated with this category, are you still want to change it.?",})
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});
	useMemo(() => {
		if(isEdit) 
     {setView("create_new") }
    else
    {setView("view_all")
    setForm({sequence:Number(tableData.tableBody[tableData.tableBody.length-1]?.["sequence"])+1});
  }
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({sequence:Number(tableData.tableBody[tableData.tableBody.length-1]?.["sequence"])+1});
	}, [location]);
	
  

	const formTitle = isEdit ? "Edit Category" : "Create Category";


	
	useEffect(()=>{
		dispatch(all_category_list())
	},[])
	
	
	const categories = useSelector((state) => state.masters.categories);
	
	useMemo(()=>{
		
		if(categories?.statuscode==200){const temp=tableData
		temp.tableBody=categories?.data
		setTableData({...temp})
		setForm({...form,sequence:Number(tableData.tableBody[tableData.tableBody.length-1]?.["sequence"])+1})
	}
	},[categories])
	
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof(form?.[element.name])!="string") {
				const temp = form;
				temp["temp"+element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit)
			{
		dispatch(category_update(data))
		}
		else
			{
			dispatch(category_create(data))
		}
		
		
	};
	useMemo(()=>{
		if(message?.statuscode==200)
		{
		
		setIsEdit(false);
		setForm({});
		setTimeout(() => {
			dispatch(all_category_list());
		}, 1000);
		setView("view_all");}
		else{
			
		setForm({...form,category_image:form?.tempcategory_image });
		}
	},[message])
	return (
		<>
		
			<ViewChange
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
			/>

			{view == "create_new" && (
				<Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
	
			
		</>
	);
}
