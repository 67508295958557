import React from "react";
import AddIcon from '@mui/icons-material/Add';
import SRTFile from "./../../../images/SRTFile.png"
export const FormStructure= (isEdit) => {

	return [
	[
		{
			id: "1",
			type: isEdit?"inputBox":"select",
			title: "Select Series",
			name: "series_name",
			options: ["Series", "Series", "Series"],
			required:true,
			disabled:isEdit
		},
		{
			id: "3",
			type: "inputBox",
			title: "Show Access",
			name: "series_type",
			disabled:"true"
		},
		{
			id: "2",
			type: "select",
			title: "Select Season",
			name: "season",
			options: ["Season 1", "Season 2", "Season 3"],
			required:true
		},
		{
			id: "2",
			type: "select",
			title: "Select Episode No",
			name: "episode_number",
			options: ["Episode 1", "Episode 2", "Episode 3","Episode 4", "Episode 5", "Episode 6","Episode 7", "Episode 8", "Episode 9","Episode 10", "Episode 11", "Episode 12","Episode 13", "Episode 14", "Episode 15"],
			required:true
		},
	
		{
			id: "4",
			type: "inputBox",
			title: "Episode Title",
			name: "episode_title",
			maxLength:50,
regex : /^[a-zA-Z0-9\s\&\"\,\.\-]+$/,
required:true
		},
		
		{
			id:"9",
			type:"select",
			title:"Publish Type",
			name:"publish_type",
			options:["Now","Later"],
			required:true
			
		},
		{
			id: "5",
			type: "inputBox",
			variant: "date",
			title: "Release Date",
			name: "release_date",
			min:new Date().toISOString().split('T')[0],
			required:true,
			display:"none"
		},
		{
			id: "5",
			type: "inputBox",
			variant: "time",
			title: "Release Time",
			name: "release_time",
			required:true,
			display:"none"
		},
		
		{
			id: "7",
			type: "select_multiple",
			title: "Select Cast",
			name: "series_cast",
			maxSelections:"3",
        errorText:"You can select max 3 cast at a time",
			options: ["Portrait", "Landscape"],
			required:true
		},
		{
			id: "8",
			type: "description",
			title: "Description",
			name: "episode_description",
			required:true
		},
	],
	[
		{
			id: "9",
			type: isEdit?"lockedInput":"inputBox",
			title: "M3U8 URL ( Resolution: 360p)",
			endsWith:".m3u8",
        errorText:"File extension should be .m3u8 only",
			name: "episodeLink_360p",
			required:true
		},
		{
			id: "10",
			type: isEdit?"lockedInput":"inputBox",
			title: "M3U8 URL ( Resolution: 480p)",
			endsWith:".m3u8",
        errorText:"File extension should be .m3u8 only",
			name: "episodeLink_480p",
			required:true
		},
		{
			id: "11",
			type: isEdit?"lockedInput":"inputBox",
			title: "M3U8 URL ( Resolution: 720p)",
			endsWith:".m3u8",
        errorText:"File extension should be .m3u8 only",
			name: "episodeLink_720p",
			required:true
		},
		{
			id: "12",
			type: isEdit?"lockedInput":"inputBox",
			title: "M3U8 URL ( Resolution: 1080p)",
			endsWith:".m3u8",
        errorText:"File extension should be .m3u8 only",
			name: "episodeLink_1080p",
			required:true
		},
		{
			id: "13",
			type: isEdit?"lockedInput":"inputBox",
			title:"Upload Mp4 URL",
			endsWith:".mp4",
        errorText:"File extension should be .mp4 only",
        name:"episodeMp4_URL",
		required:true
		},
		{
			id: "6",
			type: "inputBox",
			title: "Episode duration",
			name: "episode_duration",
			disabled:true
		},
		{
			id: "18",
			type: "toggle",
			title: "Is This Video 18+ ?",
			name: "episode_is_for18Plus",
			size:"6",
			default:"No",
			options: [
				{ value: "Yes", color: "success" },
				{ value: "No", color: "danger" },
			],
			
		},
		{type:"undefined"}

		,
		// {
		// 	id:"5",
		// 	type:"image",
		// 	title:"Browse Thumbnail",
		// 	subtitle:"(Resolution : 910 x 1280)",
		// 	subsubtitle:"Max File Size 1MB",
		// 	subsubsubtitle:"Support only JPG,PNG,JPEG",
		// 	name:"episode_thumbnail",
		// 	required:true
		// },
		 {
			id:"6",
			type:"image",
			title:"Browse Poster",
			subtitle:"(Resolution : 1280 x 720)",
			subsubtitle:"Max File Size 1MB",
			subsubsubtitle:"Support only JPG,PNG,JPEG",
			size:"4",
			name:"episode_poster",
			required:true
		},
	],

	
	
];
}