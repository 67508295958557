

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Category from '../components/Masters/Category/Category';
import SubCategory from '../components/Masters/SubCategory/SubCategory';
import Language from '../components/Masters/Language/Language';
import Genre from '../components/Masters/Genre/Genre';
import Cast from '../components/Masters/Cast/Cast';
import Layout from '../components/Layout/Layout';

function MastersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Category/*' element={<Layout children={Category()}/>}/>

< Route path='/SubCategory/*' element={<Layout children={SubCategory()}/>}/>

< Route path='/Language' element={<Layout children={Language()}/>}/>
< Route path='/Genre' element={<Layout children={Genre()}/>}/>
< Route path='/Cast' element={<Layout children={Cast()}/>}/>
      </Routes>
    </div>
  );
}

export default MastersModule;
