import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Stack
        sx={{
         
          // backgroundColor: "#fff",
          p: "25px",
          borderRadius: "10px 10px 0 0",
          textAlign: "center",
          mt: "15px"
        }}
        className="footer"
      >
        <Box >
          <Typography>
          Designed and Developed by <a target="_blank" href="https://www.myott.stream/" style={{color:"inherit",fontWeight:"600"}}>MyOTT</a>. All rights reserved 2023
           
          
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default Footer;
