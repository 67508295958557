
import { useSelector } from "react-redux"

export const TableData= () => {

  return {
    tableTitle:"Series",
    tableHead:[
        {
            id: 'series_name',
            label: 'Series Name',
          },
          {
            id: 'series_poster',
            label: 'Image',
            isImage:true
          },
          {
            id: 'season',
            label: 'Season',
          },
          {
            id: 'series_type',
            label: 'Access Type',
          },
          // {
          //   id: 'seriesViews',
          //   label: 'Views',
          // },
          
          
          {
            id: 'language',
            label: 'Language',
          },
          
        
          

          {
            id: 'status',
            label: 'Status',
          },
          {
            id: 'edit',
            label: 'Update',
          },
    ],
    tableBody:[
 
    ],
    filterColumn:[
      {
        id: "1",
      title: "Access Type",
      name: "series_type",
      options:["FREE","TVOD","SVOD"],
      },
      {
        id: "2",
      title: "Language",
      name: "language",
      options:["English","Hindi","Gujarati"],
      },
     
    ]
  }}