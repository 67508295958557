import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "./../../images/trash.png";
import { visuallyHidden } from "@mui/utils";
import Card from "@mui/material/Card";
import EditTwoToneIcon from "./../../images/edit.png";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IMAGE } from "../../api";
import FormControl from "@mui/material/FormControl";
import EnlargedView from "./EnlargedView";
import Grid from "@mui/material/Grid";
import { DateRangePicker } from "rsuite";
import Autocomplete from '@mui/material/Autocomplete';
import Popup from "./Popup"
import { useEffect } from "react";
import empty from "./../../images/empty.png"
function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		headCells,
		disableDelete,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead
			sx={{
				background:
				"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",
			}}
			style={{ color: "white" }}
		>
			<TableRow>
				{disableDelete && (
					<TableCell
						padding="checkbox"
						sx={{
							borderBottom: "1px solid #F7FAFF",
							fontSize: "13.5px",
							padding: "10px 10px",

						}}

					>
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/>
					</TableCell>
				)}
				{headCells.map((headCell) => (
					<TableCell
						sx={{
							borderBottom: "1px solid #F7FAFF",
							fontSize: "13.5px",
							padding: headCell?.padding || "10px 8px",
							fontWeight: 600,
						}}
						key={headCell.id}
						align={(headCell.isImage) ? "center" : (headCell?.align || "left")}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{(headCell.isImage || headCell.id == "edit") ? <p style={{ color: "#f1f3f4" }}>{headCell.label}</p> :
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								<p style={{ color: "#f1f3f4" }}>{headCell.label}</p>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	headCells: PropTypes.array.isRequired,
	disableDelete: PropTypes.bool.isRequired,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		title,
		column_sum,
		deleteRows,
		setSearch,
		search,
		setFilter,
		filter,
		filterColumn,
		isDateRangeFilter,
		dateRange,
		setDateRange,
		addButton,
		exportButton
	} = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },

			}}
			className="ss-table-toolbar"
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: "1 1 100%" }}
					color="black"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (


				<Typography
					style={{ width: "max-content", whiteSpace: "nowrap" }}
					variant="p"
					color="black"
					fontSize={16}
					id="tableTitle"
					component="div"
					fontWeight={600}
				>
					<p>{title}</p>

					<Typography

						style={{ width: "max-content", whiteSpace: "nowrap" }}
						variant="p"
						color="black"
						fontSize={16}
						id="amount"
						component="div"
						fontWeight={600}
					>
						<p>{column_sum}</p>

					</Typography>
				</Typography>


			)
			}

			{numSelected > 0 ? (
				<Tooltip title="Delete" onClick={() => deleteRows()}>
					<IconButton>
						<img src={DeleteIcon} height={"20px"} />
						{/* <DeleteIcon /> */}
					</IconButton>
				</Tooltip>
			) : (
				<Grid
					container
					justifyContent="flex-end"
					alignItems={"center"}
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 2 }}
				>

					{isDateRangeFilter && (
						<Grid
							item
							xs={10}
							sm={4}
							md={3}
							lg={
								filterColumn?.length >= 2 ? (filterColumn?.length > 5 ? 12 : 10) / (filterColumn?.length + (isDateRangeFilter ? 2 : 1)) : 2
							}
							key={"dateRange"}
						>
							<FormControl fullWidth>
								<DateRangePicker
									size="md"
									placeholder={"Date range"}
									ranges={[]}
									name={"value.name"}
									value={dateRange || ""}
									format="yyyy-MM-dd hh:mm aa"
									onChange={(range) => { setDateRange(range) }
									}
									defaultCalendarValue={[new Date(), new Date()]}
								/>
							</FormControl>
						</Grid>
					)}



					{filterColumn?.map((value, index) => (


						value?.displayOn ?
							(filter?.[value?.displayOn] &&
								<Grid
									item
									xs={10}
									sm={4}
									md={3}
									lg={
										filterColumn?.length >= 2 ? (filterColumn?.length > 5 ? 12 : 10) / (filterColumn?.length + (isDateRangeFilter ? 2 : 1)) : 2
									}
									key={index + "filter"}
								>
									<FormControl fullWidth size="small">
										<Autocomplete
											disablePortal
											id="demo-simple-select-label"
											options={value.options.map((subcategory) => (subcategory?.category_name == filter?.[value?.displayOn] && subcategory?.subcategory_name)).filter(e => e)}
											name={value.name}
											isOptionEqualToValue={(option, value) =>
												option.value === value.value
											}
											// size="small"
											value={filter?.[value.name] || ""}
											onChange={(event, newValue) => {

												setFilter({ ...filter, [value.name]: newValue?.value || newValue, });

											}}


											defaultValue=""
											renderInput={(params) => (
												<TextField
													{...params}
													label={value.title}
													size="small"

												/>
											)}
										/></FormControl>

								</Grid>) :
							<Grid
								item
								xs={10}
								sm={4}
								md={3}
								lg={
									filterColumn?.length >= 2 ? (filterColumn?.length > 5 ? 12 : 10) / (filterColumn?.length + (isDateRangeFilter ? 2 : 1)) : 2
								}
								key={index + "filter"}
							><FormControl fullWidth size="small">

									<Autocomplete
										disablePortal
										id="demo-simple-select-label"
										options={value.options}
										name={value.name}
										isOptionEqualToValue={(option, value) =>
											option.value === value.value
										}
										size="small"
										value={filter?.[value.name] || ""}
										onChange={(event, newValue) => {
											console.log(newValue, "110110")
											setFilter({ ...filter, [value.name]: newValue?.value || newValue, });

										}}
										defaultValue=""

										renderInput={(params) => (
											<TextField
												{...params}
												label={value.title}
												size="small"

											/>
										)}
									/>
								</FormControl></Grid>
					))}
					<Grid
						item
						xs={10}
						sm={4}
						md={3}
						lg={filterColumn?.length >= 2 ? (filterColumn?.length > 5 ? 12 : 10) / (filterColumn?.length + (isDateRangeFilter ? 2 : 1)) : 3}
						key={"Search"}
					>
						<TextField
							id="search-bar"
							className="text"
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							label="Search"
							variant="outlined"
							placeholder="Search..."
							size="small"
							value={search}
						/>
					</Grid>
					{addButton && <Grid item>{addButton}</Grid>}
					{exportButton && <Grid item>{exportButton}</Grid>}


				</Grid>
			)}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	column_sum: PropTypes.string,
	deleteRows: PropTypes.func.isRequired,
	setSearch: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	setFilter: PropTypes.func.isRequired,
	filter: PropTypes.object.isRequired,
	filterColumn: PropTypes.array.isRequired,
	isDateRangeFilter: PropTypes.string,
	setDateRange: PropTypes.func,
	dateRange: PropTypes.object,
	addButton: PropTypes.object,
	exportButton: PropTypes.object,
};

export default function ListTable({
	setTableData,
	tableData,
	setForm,
	setIsEdit,
	setContent,
	addButton,
	exportButton
}) {
	const dispatch = useDispatch();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(tableData?.rowsPerPage || 10);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event, rows) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - tableData.tableBody.length)
			: 0;

	//Delete row/rows
	const [openAccess, setOpenAccess] = useState(false);
	const [contentAccess, setContentAccess] = useState();
	const [open, setOpen] = useState(false);
	const [popupType, setPopupType] = useState("")
	const [content, setPopupContent] = useState("")
	const [result, setResult] = useState(undefined)
	const deleteRows = () => {
		setOpen(true)
		setPopupType("Delete")
		setPopupContent(tableData.onDeleteText)

	};
	//Update Status
	const [changeRow, setChangeRow] = useState({})
	const [changeValue, setChangeValue] = useState({})
	const updateStatus = (row, value) => {
		setOpen(true)
		setPopupType("Update")
		setPopupContent(tableData?.customisedStatusUpdateMessage ? (row?.status?.toLowerCase() == "inactive" ? tableData?.onActiveText : tableData?.onInactiveText) : tableData.onUpdateText)
		setChangeRow({ ...row })
		setChangeValue({ ...value })

	};
	useMemo(() => {

		if (popupType == "Delete") {
			if (result) {
				const tempTableData = tableData;
				const tempTableBody = [];

				tempTableData.tableBody.map((value) => {
					if (selected.includes(value.id)) {
						const data = new FormData();
						data.append("id", value.id);
						dispatch(tableData.deleteRecord(data));
					} else tempTableBody.push(value);
				});
				tempTableData.tableBody = tempTableBody;
				setSelected([]);
				setTableData({ ...tempTableData });
			} else {
				setSelected([]);
			}
		}
		else if (popupType == "Update") {
			if (result) {

				const data = new FormData();
				const temp = tableData;
				if (changeRow[changeValue.id] == (changeValue.keywords?.[0] || "Active")) {
					Object.keys(changeRow).map((key) =>
						key == "status"
							? data.append(key, changeValue.keywords?.[1] || "Inactive")
							: data.append(key, changeRow?.[key])
					);

					temp.tableBody.map(
						(tempValue, index) =>
							tempValue.id == changeRow.id &&
							(temp.tableBody[index]["status"] =
								changeValue.keywords?.[1] || "Inactive")
					);

					dispatch(tableData.updateRecord(data));
				} else {
					Object.keys(changeRow).map((key) =>
						key == "status"
							? data.append(key, changeValue.keywords?.[0] || "Active")
							: data.append(key, changeRow?.[key])
					);
					temp.tableBody.map(
						(tempValue, index) =>
							tempValue.id == changeRow.id &&
							(temp.tableBody[index]["status"] = changeValue.keywords?.[0] || "Active")
					);
					dispatch(tableData.updateRecord(data));
				}

				setTableData({ ...temp });
				setChangeRow({})
				setChangeValue({})
			}
		}
		setResult(undefined)
	}, [result])



	//Search

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({});


	const [dateRange, setDateRange] = useState();
	const defaultArray = tableData?.tableBody.filter((item) => {
		for (let i = 0; i < tableData?.filterColumn?.length || 0; i++) {
			let name = tableData?.filterColumn[i]?.name;
			if (
				tableData?.filterColumn[i]?.default != undefined &&
				!String(item?.[name]).includes(tableData?.filterColumn[i]?.default)
			) {
				return false;
			}
		}
		return true;
	});
	// const totelAmounts = tableData?.tableBody.filter((item)=>{
	// 	for(let i = 0; i<tableData?.filterColumn?.length || 0; i++){
	// 		let amount = tableData?.filterColumn[i].name;
	// 		if(tableData?.filterColumn[i]?.default1)
	// 	}
	// })

	const [results, setResults] = useState([...defaultArray]);
	useMemo(() => {
		setTableData({ ...tableData, exportData: [...tableData.tableBody] });
		setResults([...tableData.tableBody]);
	}, [tableData.tableBody]);
	useMemo(() => {
		const dateFilteredArray = tableData.tableBody.filter((item) => {
			let date = new Date(item?.[tableData.isDateRangeFilter])
			if (dateRange && !(dateRange[0] < date && date < dateRange[1])) {
				return false
			}

			return true
		})


		const filteredArray = dateFilteredArray.filter((item) => {
			for (let i = 0; i < tableData?.filterColumn?.length || 0; i++) {
				let name = tableData?.filterColumn[i]?.name;
				if (
					filter?.[name] != undefined &&
					!String(item?.[name]).includes(filter?.[name])
				) {
					return false;
				}
			}
			return true;
		});


		const temp = filteredArray.filter((item) => {
			let found = false;
			Object.entries(item).map(([key, value]) => {
				if (String(value).toLowerCase().includes(search.toLowerCase())) {
					found = true;
				}
			});
			return found;
		});

		setResults([...temp]);
		setTableData({ ...tableData, exportData: [...temp] });
	}, [search, filter, dateRange]);
	useEffect(() => {

		const tempFilter = filter
		console.log(filter, "huiiiiii")
		tableData?.filterColumn?.map((value) => {
			if (value?.default != undefined) {
				tempFilter[value?.name] = value?.default
			}
		})
		setTimeout(() => {

			setFilter({ ...tempFilter })
		}, 900)
			;
	}, [tableData?.filterColumn])
	useEffect(() => {
		const filteredArray = tableData?.tableBody.filter((item) => {
			for (let i = 0; i < tableData?.filterColumn?.length || 0; i++) {
				let name = tableData?.filterColumn[i]?.name;
				if (
					tableData?.filterColumn[i]?.default != undefined &&
					!String(item?.[name]).includes(tableData?.filterColumn[i]?.default)
				) {
					return false;
				}
			}
			return true;
		});
		setTimeout(() => {

			setResults([...filteredArray]);
			// console.log(filteredArray, "fffffff")
		}, 900)
		setTableData({ ...tableData, exportData: [...filteredArray] })
	}, [tableData?.filterColumn])
	useMemo(() => {
		if (selected.length != 0) {
			const tempTableData = tableData;
			const tempTableBody = [];

			tempTableData.tableBody.map(
				(value) => selected.includes(value.id) && tempTableBody.push(value)
			);

			setTableData({ ...tableData, exportData: [...tempTableBody] });
		} else {
			setTableData({ ...tableData, exportData: tableData.tableBody });
		}
	}, [selected]);



	return (
		<>
			<EnlargedView open={openAccess} setOpen={setOpenAccess} content={contentAccess} />
			<Popup open={open} setOpen={setOpen} content={content} setResult={setResult} />
			<Card
				sx={{

					boxShadow: "var(--gradientColorLightest2) 0px 6px 12px -2px, var(--gradientColorLightest1) 0px 3px 7px -3px",

					borderRadius: "10px",
					p: "25px",
					mb: "15px",
				}}
			>
				<Box sx={{ width: "100%" }}>

					<EnhancedTableToolbar
						numSelected={selected.length}
						title={tableData.tableTitle + " : " + results?.length}
						column_sum={tableData?.column_sum && (tableData?.column_sum?.title + " : " + "₹" + results?.map((ele) => Number(ele?.[tableData?.column_sum?.name])).reduce((sum, i) => sum + i, 0).toFixed(2))}
						deleteRows={deleteRows}
						setSearch={setSearch}
						filter={filter}
						setFilter={setFilter}
						filterColumn={tableData?.filterColumn || []}
						search={search}
						isDateRangeFilter={tableData?.isDateRangeFilter}
						dateRange={dateRange}
						setDateRange={setDateRange}
						addButton={addButton}
						exportButton={exportButton}
					/>

					<TableContainer
						component={Paper}
						sx={{
							boxShadow: "none",
						}}
					>
						<Table
							// sx={{ minWidth: 950 }}
							aria-labelledby="tableTitle"
							className="dark-table sorting-selecting-table"

						>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={(event) => handleSelectAllClick(event, results)}
								onRequestSort={handleRequestSort}
								rowCount={results?.length}
								headCells={tableData.tableHead}
								disableDelete={!tableData.disableDelete}
							/>
							{results.length != 0 ?
								<TableBody>
									{/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.sort(getComparator(order, orderBy)).slice() */}
									{stableSort(results || [], getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											const isItemSelected = isSelected(row?.id);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<TableRow
													hover
													role="checkbox"
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={index.id + "TableRow"}
													selected={isItemSelected}
													sx={{
														backgroundColor: row?.color || "white"
													}}
												>
													{!tableData.disableDelete && (
														<TableCell
															padding="checkbox"
															key={row?.id + "TableCell"}
															sx={{
																fontWeight: "500",
																borderBottom: "1px solid #F7FAFF",
																padding: "8px 10px",
																fontSize: "13px",
															}}
														>
															<Checkbox
																color="primary"
																key={row?.id + "Checkbox"}
																onClick={(event) => handleClick(event, row?.id)}
																checked={isItemSelected}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													)}
													{!tableData.tableHead[0]?.isSpecial && <TableCell
														component="th"
														id={labelId}
														key={labelId}
														scope="row"
														sx={{
															borderBottom: "1px solid #F7FAFF",
															padding: "8px 10px",
															width: tableData.tableHead[0]?.width || "auto"
														}}
													>
														<Link
															style={{ color: "inherit" }}
															to={tableData.tableHead[0].link}
															state={{ id: row?.id }}
														>
															<p
																style={{
																	color: tableData.tableHead[0].color || "black",
																}}
															>
																{row[tableData.tableHead[0].id]}
															</p>
															{tableData.tableHead[0]?.subText && <span style={{ color: "black" }}>({row[tableData.tableHead[0]?.subText]})</span>}
														</Link>
													</TableCell>}
													{tableData.tableHead.map((value, index) => {
														if (value.isSpecial) {
															return (
																<TableCell
																	sx={{
																		borderBottom: "1px solid #F7FAFF",
																		padding: "8px 10px",
																		fontSize: "13px",
																		width: value?.width || "auto"
																	}}
																	align={value?.align || "center"}
																	key={index}

																>
																	{row[value.id]}
																</TableCell>
															);
														}
														else if (value.isImage) {
															return (
																<TableCell
																	sx={{
																		borderBottom: "1px solid #F7FAFF",
																		padding: value?.padding || "8px 8px",
																		fontSize: "13px",
																		width: value?.width || "auto",

																		cursor: value?.isModal && row[value.id] && "pointer",
																	}}
																	align="center"
																	key={index}
																	onClick={() => {
																		if (value?.isModal && row[value.id]) {
																			setContent(
																				<img height={"500px"} src={IMAGE + row[value.id]} />
																			);
																			tableData.openModal();
																		}
																	}}
																>

																	{row[value.id] ? (value?.isModal ? <img src="https://i.ibb.co/3RxybqZ/photo.png" height={"30px"} /> : <img src={IMAGE + row[value.id]} />) : <p>_</p>}
																</TableCell>
															);
														}
														else if (value.isButtonDisplay) {
															return (
																<TableCell
																	sx={{
																		borderBottom: "1px solid #F7FAFF",
																		padding: "8px 10px",
																		fontSize: "13px",
																		width: value?.width || "auto"
																	}}
																	align="left"
																	key={index}
																>
																	<span
																		// 												sx={{
																		//   textTransform: 'capitalize',
																		//   borderRadius: '10px',
																		//   p: '10px 30px',
																		//   fontSize: '14px',
																		//   color: '#fff !important',

																		// }}
																		style={{ cursor: "pointer", fontWeight: "500" }}
																		variant="contained"
																		className={row[value.id] + "Badge"}


																	>
																		{row[value.id]}
																	</span>
																</TableCell>
															);
														}
														else if (value.id == "status") {
															return (
																<TableCell
																	sx={{
																		borderBottom: "1px solid #F7FAFF",
																		padding: "8px 10px",
																		fontSize: "13px",
																	}}
																	align="left"
																	key={index}
																>
																	<span
																		// 												sx={{
																		//   textTransform: 'capitalize',
																		//   borderRadius: '10px',
																		//   p: '10px 30px',
																		//   fontSize: '14px',
																		//   color: '#fff !important',

																		// }}
																		style={{ cursor: "pointer", fontWeight: "500" }}
																		variant="contained"
																		className={
																			row[value.id]?.toLowerCase() ==
																				(value.keywords?.[0]?.toLowerCase() || "active")
																				? "successBadge"
																				: "dangerBadge"
																		}
																		//  color={row[value.id]==(value.keywords?.[0]||"Active")?"success":"error"}
																		onClick={() => {
																			if (row[value.id] != value.nonEditableState)
																				updateStatus(
																					{ id: row?.id, status: row?.status },
																					value
																				);
																		}}
																	>
																		{row[value.id]}
																	</span>
																</TableCell>
															);
														} else if (value.id != "edit" && index != 0) {
															return (
																<TableCell
																	sx={{
																		borderBottom: "1px solid #F7FAFF",
																		padding: value?.padding || "8px 8px",
																		fontSize: "13px",
																		width: value?.width || "auto",
																		cursor: value?.isModal && "pointer",
																	}}
																	align={value?.align || "left"}
																	key={index}
																	onClick={() => {
																		if (value?.isModal) {
																			setContent(
																				<p style={{ color: "black" }}>
																					{row[value.id]}
																				</p>
																			);
																			tableData.openModal();
																		}
																	}}
																>
																	<p style={{ color: "black" }}>
																		{value?.isModal
																			? (value?.default || row[value.id].substring(0, 15) + "...")
																			: typeof row[value.id] == "object"
																				? row[value.id]?.toString()
																				: row[value.id]}
																	</p>
																	{value?.subText && <span>({row[value?.subText]})</span>}
																	{/* <p style={{color:"black"}}>{value?.isModal?row[value.id].substring(0, 15)+"...":row[value.id]}</p> */}
																</TableCell>
															);
														} else if (value.id == "edit") {
															return (
																<TableCell
																	sx={{
																		borderBottom: "1px solid #F7FAFF",
																		padding: "8px 10px",
																		fontSize: "13px",
																		width: value?.width || "auto"
																	}}
																	align="left"
																	key={index}
																>
																	<img
																		src={EditTwoToneIcon}
																		style={{
																			cursor: "pointer",
																			borderRadius: "0",
																			verticalAlign: "baseline",
																		}}
																		height={"20px"}
																		onClick={() => {
																			setForm({ ...row });
																			setIsEdit(true);

																		}}
																	/>
																</TableCell>
															);
														}
													})}
												</TableRow>
											);
										})}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: (dense ? 33 : 53) * emptyRows,
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody> :
								<TableBody ><TableRow >
									<TableCell colSpan={"10"} >
										<div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center", marginTop: "2rem" }}>	<img src={empty} alt="empty" />

											<p style={{ color: "black", textAlign: "center", }}>No data found for this module </p>
										</div>
									</TableCell>
								</TableRow>
								</TableBody>}
						</Table>
					</TableContainer>
							
					<TablePagination
						rowsPerPageOptions={[tableData?.rowsPerPage !== undefined ? tableData?.rowsPerPage : 10, ]}
						component="div"
						count={results?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						className="ss-table-table-pagination"
					/>
				</Box>
			</Card></>
	);
}
