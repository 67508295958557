import React from "react";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styles from "./Authentication.module.css";
import './Authentication.css'
import favicon from "./../../images/logo.png"
import { forgot_password } from "../../actions/authenticate";
import EmailIcon from '@mui/icons-material/Email';
import { InputAdornment } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
const ForgotPasswordForm = () => {
  const dispatch=useDispatch()
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  
    dispatch(forgot_password({
      username: data.get("username"),
    }))
  };
  const message = useSelector((state) => state.layout.message);
  return (
    <>
      <div className={styles.authenticationBox+" dark"}>
        <Box
          component="main"
          sx={{
            maxWidth: "450px",
            // mr: "10%",
            padding: "20px",
            borderRadius:"10px",
            boxShadow: "var(--gradientColor2) -2px -2px 5px 1px,var(--gradientColor2) -2px 2px 5px 1px,var(--gradientColor2) 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
            // boxShadow: "var(--gradientColorLighter2) 0px 6px 5px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
          
          }}
        >
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Box>
           

              <Box component="form" sx={{backgroundColor:"white",borderRadius:"10px",p:"20px"}} noValidate onSubmit={handleSubmit}>
              <Grid container justifyContent={"center"} sx={{mb:3}}>
            <img
                  src={favicon}
                  alt="favicon"
                  className={styles.favicon}
                  width={"75px"}
                /></Grid>
              <Typography as="h1" fontSize="24px" textAlign={"center"} fontWeight="700" mb="5px">
                Forget Password{" "}
              
              </Typography>

              <Typography fontSize="15px" mb="5px" >
                We′ll send you instructions to reset your
                password after you enter your email 
              </Typography>
                <Box
                  sx={{
                    padding: "10px 20px",
                    borderRadius: "10px",
                    mb: "20px",
                  }}
                  className="bg-black"
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        component="label"
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          mb: "10px",
                          display: "block",
                        }}
                      >
                        Email
                      </Typography>

                      <TextField
                        required
                        fullWidth
                        id="username"
                        label="Email Address"
                        name="username"
                        autoComplete="username"
                        InputProps={{
                          style: { borderRadius: 8 },
                          endAdornment: (
                          <InputAdornment position="start"> 
                        <EmailIcon style={{color:"black !important"}}/>
                        </InputAdornment> 
                          ),
                          }}
                      />
                    </Grid>
                   
                  </Grid> {message?.Message}
                </Box>
           
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 1,
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    fontWeight: "500",
                    fontSize: "16px",
                    padding: "12px 10px",
                    color: "#fff !important"
                  }}
                  style={{background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)"}}
                >
                  Send Reset Link
                </Button>
                <Box as="div" textAlign="center" mt="20px">
                <Link
                  to="/Authentication/SignIn/"
                  className={"primaryColor text-decoration-none "+styles.whiteText}
                >
                  <i className="ri-arrow-left-s-line"></i> Back to Sign in
                </Link>

              </Box>
              </Box>

              
            </Box>
          </Grid>
        </Box>
      </div>
      <p className={styles.authFooter}>Designed and Developed by <a target="_blank" href="https://www.myott.stream/" style={{color:"inherit",fontWeight:"600",marginLeft:"4px"}}>MyOTT</a>. All rights reserved 2023</p>
     
    </>
  );
};

export default ForgotPasswordForm;
