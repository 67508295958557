import React from "react";

import styles from "./Profile.module.css";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import profile from "./../../images/photo.png"
const ProfileContent = ({data}) => {
  return (
    <>
      <div className={styles.profileBox}>
        

        <div className={styles.profileInfoContent}>
        <Card
						sx={{
							textAlign: "center",
							boxShadow: "var(--gradientColorLightest2) 0px 6px 12px -2px, var(--gradientColorLightest1) 0px 3px 7px -3px",
							borderRadius: "10px",
							p: "20px 15px",
							
						}}
					>
						<img
							src={profile}
							alt="Member"
							height={"150px"}
							className="borRadius100"
						/>
						<Typography
							as="h4"
							sx={{
								fontSize: 16,
								fontWeight: 500,
								mt: "10px",
							}}
						>
							{data?.firstName+" "+data?.lastName}
						</Typography>

            <Typography
                sx={{
                  fontSize: 13,
                  color: '#A9A9C8',
                 
                }}
              >
                {data?.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  color: '#A9A9C8',
                 
                }}
              >
              {data?.mobileNumber}
              </Typography>
					</Card>
          
        </div>
      </div>
    </>
  );
};

export default ProfileContent;
