
export const MESSAGE = 'MESSAGE';
export const LOGGEDIN = 'LOGGEDIN';
export const PROFILE = 'PROFILE';



// Masters
export const CATEGORIES = 'CATEGORIES'
export const SUBCATEGORIES= 'SUBCATEGORIES'
export const CASTS= 'CASTS'
export const GENRE= 'GENRE'
export const LANGUAGES= 'LANGUAGES'


// Subscription
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS'


// Customer
export const CUSTOMERS = "CUSTOMERS"
export const CUSTOMER = "CUSTOMER"
export const ACTCUSTOMER = "ACTCUSTOMER"

// Merchandise
export const NOTIFICATIONS = "NOTIFICATIONS"
export const NOTIFICATIONS_COUNT = "NOTIFICATIONS_COUNT"

export const SLIDER_BANNERS = "SLIDER_BANNERS"

// Movie
export const MOVIES = "MOVIES"
export const MOVIE = "MOVIE"
export const UPCOMING_MOVIES = "UPCOMING_MOVIES"
export const MOVIE_WATCH_USER = "MOVIE_WATCH_USER"




// Transaction
export const TRANSACTIONS = "TRANSACTIONS"

// Watchhours
export const WATCHHOURS = "WATCHHOURS"

// Web Series
export const EPISODES = "EPISODES"
export const EPISODE = "EPISODE"
export const SERIES = "SERIES"
export const EPISODE_WATCH_USER = "EPISODE_WATCH_USER"


// Setting
export const ABOUTUS = "ABOUTUS"
export const PRIVACYPOLICY = "PRIVACYPOLICY"
export const TERMSCONDITIONS = "TERMSCONDITIONS"
export const REFUNDPOLICY = "REFUNDPOLICY"


// Dashboard
export const TOTALUSERS = "TOTALUSERS"
export const DASHBOARDCOUNT = "DASHBOARDCOUNT"
export const DASHBOARDMOVIES = "DASHBOARDMOVIES"
export const TOTALSUBSCRIBEUSER = "TOTALSUBSCRIBEUSER"
export const MONTHLYREVENUE = "MONTHLYREVENUE"
export const TOTALREVENUE = "TOTALREVENUE"
export const TV_SHOW_DASHBOARD = "TV_SHOW_DASHBOARD"
export const RECENT_SUBSCRIBER_DASHBOARD = "RECENT_SUBSCRIBER_DASHBOARD"
export const DAILY_REVENUE = "DAILY_REVENUE"
export const RENEWAL = "RENEWAL"



// Complaint
export const COMPLAINTS = "COMPLAINTS"
