import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import Movie from "./../../../images/Movie.png"
import Webseries from "./../../../images/Webseries_SideMenu.png"
import transactions from "./../../../images/transactions.png"
import tvod from "./../../../images/tvod.png"



const Features = ({dashboardcount}) => {
  const FeaturesData = [
  
    {
      id: "1",
      subTitle: dashboardcount?.[0]?.["data"],
      title: "Videos",
      image: Movie,
      link:"/Movie/Movie",
    },
   
    {
      id: "5",
      subTitle: dashboardcount?.[1]?.["data"],
      title: "Series",
      image: Webseries,
      link:"/WebSeries/Series/"
    },
{
      id: "3",
      subTitle:  dashboardcount?.[3]?.["data"],
      title: "Transactions",
      image: transactions,
      link:"/Transactions/Transactions"
    },
    // {
    //   id: "4",
    //   subTitle: dashboardcount?.[3]?.["data"],
    //   title: "TVOD Users",
    //   image: tvod,
    //   state:"TVOD",
    //   link:"/Transactions/Transactions"
    // },
    
    
  ].filter((e)=>e);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        {FeaturesData.map((feature) => (
          <Grid item xs={10} sm={4} md={3} lg={3} key={feature.id} >
            <Link to={feature.link} state={feature.state} style={{ textDecoration: 'none' }}>
            <Card
              sx={{
                background: "#FFF",
                borderRadius: "10px",
                p: "25px 20px",
                mb: "15px",
                boxShadow: "var(--gradientColorLightest2) 0px 6px 12px -2px, var(--gradientColorLightest1) 0px 3px 7px -3px",
                
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  
                }}
              ><Box
                  sx={{
                    width: "62px",
                    height: "62px",
                    lineHeight: "85px",
                    background: "#ffffff17",
                    borderRadius: "8px",
                    textAlign: "center",
                    
                  }}
                >
                  <img src={feature.image} alt="Graph" style={{verticalAlign:"baseline"}}/>
                </Box>
                <Box>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 14, fontWeight: 700,textAlign:"center" }}
                 
                  >
                    {feature.title}
                  </Typography>
                  <Typography variant="p"  fontSize={16} fontWeight={700}  textAlign={"center"} width={"100%"} display={"block"}>
                    {feature.subTitle}
                  </Typography>
                </Box>

                
              </Box>

            
            </Card></Link>
          </Grid>
        ))}
        
      </Grid>
    </>
  );
};

export default Features;
