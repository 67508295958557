import * as actionType from "../constants/actionTypes";

const customersReducer = (state = { customersData: null }, action) => {
	switch (action.type) {

		case actionType.CUSTOMERS:
			return { ...state, customers: action.payload };
		case actionType.CUSTOMER:
			return { ...state, customer: action.payload };
		case actionType.ACTCUSTOMER:
			return { ...state, actcustomer: action.payload }




		default:
			return state;
	}
};

export default customersReducer;
