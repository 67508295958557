


export const TableData= () => {
 
  return{    tableTitle:"Episode",

    tableHead:[
      {
        id: 'episode_number',
        label: 'Episode Number',
        subText:"episode_title",
        link:"/WebSeries/Episode/EpisodeDetails",
            color:"var(--gradientColor1)"
      },
        {
            id: 'series_name',
            label: 'Series Name',
            subText:"season"
          },
          
          {
            id: 'episode_poster',
            label: 'Image',
            isImage:true
          },
         
         
          {
            id: 'release_date',
            label: 'Release Date',
          },
          {
            id: 'seriesViews',
            label: 'Views',
          },

          {
            id: 'status',
            label: 'Status',
          },
          {
            id: 'edit',
            label: 'Update',
          },
    ],
    tableBody:[
      
    ],
    filterColumn:[
      {
        id: "1",
      title: "Series",
      name: "series_name",
      options:["Series 1","Series 2","Series 3"],
      },
      {
        id: "2",
      title: "Season",
      name: "season",
      options:["Season 1","Season 2","Season 3"],
      }
    ]
  }}