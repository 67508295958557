import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import poster from "./../../images/poster.jpg";
import "./ProductDetailsContent.css";
import Features from "./Features";
import ProductProfile from "./ProductProfile";
import {movie_details} from "./../../actions/Movie/movie"
import {episode_details} from "./../../actions/WebSeries/episode"
import { useEffect,useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
import WatchedCustomers from './WatchedCustomers'
const ProductDetailsContent = () => {
	const dispatch=useDispatch()
	const location = useLocation()
	const role=useSelector((state) => state.layout.role)
	const path = location.pathname.split('/').filter((x) => x)[1];
	const formData= new FormData()
	formData.append("id",location.state?.id)
	useMemo(()=>{
		if(path=="MovieDetails")
	dispatch(movie_details(formData))
	else
	dispatch(episode_details(formData))},[location.state?.id])
	const data =useSelector((state) =>path=="MovieDetails"? state.movies.movie?.Data:state.webseries?.episode?.Data)
	const cast = useSelector((state) =>path=="MovieDetails"? state.movies.movie?.Cast:state.webseries?.episode?.Data?.series_cast);
	const watch_hours = useSelector((state) =>path=="MovieDetails"? state.movies.movie?.watch_hours:state.webseries?.episode?.watch_hours);
	const earning =useSelector((state) =>state.movies.movie)
	
	const access=path=="MovieDetails"?data?.movie_access:data?.series_name?.series_type
	console.log(location,"parthhhh")
	return (
		<>
			<Grid
				container
				rowSpacing={1}
				justifyContent="center"
				columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
			>
				<Grid item xs={12} sm={12} md={6} lg={5} xl={5} key={"sdcsdc"}>
					<ProductProfile data={data} path={path}/>
					
					<Features earning={earning} path={path}/>
				</Grid>
				<Grid item spacing={4} xs={12} sm={12} md={6} lg={7} xl={7}>
					
					<Card
						sx={{
							boxShadow: "none",
							borderRadius: "10px",
							p: "15px 25px",
							mb: "15px",
						}}
					>
						<Box mt={2}>
							<Tabs className="product-details-tabs">
								<TabList>
									<Tab>{path=="MovieDetails"?data?.movie_category:"Episode"}</Tab>
									<Tab>Position </Tab>
									<Tab>Cast </Tab>
									
								</TabList>

								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												mb: "15px",
												fontWeight: 500,
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											{path=="MovieDetails"?data?.movie_category:"Episode"} Details
										</Typography>

										<Box>
											
										{path=="MovieDetails"&&	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Sub Category:
												</span>
												{data?.movie_subcategory}
											</Typography>}

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Access:
												</span>
												{path=="MovieDetails"?data?.movie_access:data?.series_name?.series_type}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Language:
												</span>
												{path=="MovieDetails"?data?.movie_language:data?.series_name?.language?.language_name}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Duration:
												</span>
												{path=="MovieDetails"?data?.movie_duration:data?.episode_duration}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Release Date:
												</span>
												{data?.release_date}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Watch Hours:
												</span>
												{watch_hours}
											</Typography>
										</Box>
									</Card>
								</TabPanel>
								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											Position
										</Typography>

										<Box>
										

											{path=="MovieDetails"&&<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 position:
												</span>
												{data?.set_position}
											</Typography>}

											{(access=="TVOD")&&<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Price Per View (Rent):
												</span>
												{data?.rental_price||data?.series_name?.rental_price} INR
											</Typography>}
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Is This Video 18+:
												</span>
												{data?.movie_is_for18Plus||data?.episode_is_for18Plus}
											</Typography>
										
											{access=="TVOD"&&<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Available For:
												</span>
												{data?.available_for_days} days
											</Typography>}
										</Box>
									</Card>
								</TabPanel>
								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											Cast
										</Typography>

										<Box>
										<Card
												sx={{
													fontSize: 13,
													mt: "10px",
													background:"none",
													display:"flex",
													boxShadow:'none',
													color:"black",
													
												}}
											>
											{cast?.map((ele)=>(
											
											<Card
												sx={{
													fontSize: 13,
													mt: "10px",
													background:"none",
													display:"flex",
													boxShadow:'none',
													color:"black",
													flexDirection:"column",
													textAlign:"center",
													width:"fit-content",
													ml:"15px"
												}}
											>
												
											<p>{ele?.cast_name}</p>	
												<p>{ele?.cast_type}</p>
												<img src={IMAGE+ele?.cast_image} width="100px" style={{borderRadius:"10px"}}/>
											
												</Card>
											
											
))}</Card>
											
										</Box>
									</Card>
								</TabPanel>
								
								
							</Tabs>
						</Box>
					</Card>
					
					{/* <Grid
						container
						item
						xs={12}
						md={12}
						lg={12}
						xl={12}
						rowSpacing={1}
						justifyContent="space-evenly"
						columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
					>
						<Card
							sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px",
								mb: "15px",
								width: "100%",
								display: "flex",
								justifyContent: "center",
								background: "transparent",
								textAlign: "center",
								color: "black",
							}}
						>
							<div>
								<img
									src={IMAGE+(data?.movie_poster||data?.episode_poster)}
									alt="Image"
									style={{
										display: "block",
										borderRadius: "10px",
										margin: "0 auto",
										height: "250px",
									}}
								/>
								<p>Poster</p>
							</div>
						</Card>
					
					</Grid> */}
				</Grid>
			</Grid>
			{<WatchedCustomers id={location?.state?.id} path={path}/>}
		</>
	);
};

export default ProductDetailsContent;
