import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


import  DashboardIcon  from "./../../../images/dashboard_SideMenu.png";
import  WebSeriesIcon  from "./../../../images/Webseries_SideMenu.png";
import  WatchhoursIcon  from "./../../../images/watchhours_SideMenu.png";
import  TransactionIcon  from "./../../../images/Transactions_SideMenu.png";
import  SubscriptionIcon  from "./../../../images/subscription_SideMenu.png";
import  MovieIcon  from "./../../../images/Movie_SideMenu.png";
import  MastersIcon  from "./../../../images/Masters_SideMenu.png";
import  CustomersIcon  from "./../../../images/customers_SideMenu.png";
import  ComplaintIcon  from "./../../../images/Complaint_SideMenu.png";
import SettingIcon from './../../../images/Setting_SideMenu.png'
import SliderBannerIcon from './../../../images/SliderBanner_SideMenu.png'

export const SidebarData= () => { 
 
  return [
  {
    title: "Dashboard",
    path: "/Dashboard/Dashboard",
    icon: <img src={DashboardIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
 
    
  },
 
  {
    title: "Videos",
    path: "/Movie/",
    icon: <img src={MovieIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    subNav: [
      {
        
        title: "Videos",
        path: "/Movie/Movie",
      },
     {
        title: "Upcoming Videos",
        path: "/Movie/UpcomingMovie/",
      },
      
    ],
  },
  
  
  {
    title: "Web Series",
    path: "/WebSeries/",
    icon: <img src={WebSeriesIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    subNav: [
      {
        title: "Series",
        path: "/WebSeries/Series/",
      },
      {
        title: "Episode",
        path: "/WebSeries/Episode/",
      },
    ].filter(e=>e),
  },

  
  {
    title: "Slider Banner",
    path: "/SliderBanner/SliderBanner",
    icon: <img src={SliderBannerIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
   
  },
  {
    title: "Customers",
    path: "/Customer/Customer",
    icon: <img src={CustomersIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    
  },
  {
    title: "Transactions",
    path: "/Transactions/Transactions",
    icon:<img src={TransactionIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
   
  },

  // {
  //   title: "Watch-hours",
  //   path: "/WatchHours/WatchHours",
  //   icon:<img src={WatchhoursIcon} height={"30px"}/>,
  //   iconClosed: <KeyboardArrowRightIcon />,
  //   iconOpened: <KeyboardArrowDownIcon />,
    
  // },
 
   {
    title: "Complaints",
    path: "/Complaint/Complaint",
    icon:<img src={ComplaintIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    
  },
  
  {
    title: "Subscriptions",
    path: "/Subscriptions/Subscriptions",
    icon: <img src={SubscriptionIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    
  },
 
  
  


  {
    title: "Masters",
    path: "/Masters/",
    icon:<img src={MastersIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    subNav: [
    
      {
        title: "Category",
        path: "/Masters/Category/",
      },
      {
        title: "Sub Category",
        path: "/Masters/SubCategory/",
      },
      {
        title: "Language",
        path: "/Masters/Language/",
      },
      {
        title: "Genre",
        path: "/Masters/Genre/",
      },
      {
        title: "Cast",
        path: "/Masters/Cast/",
      },
     
      
    ].filter(e=>e),
  },
  {
    title: "Settings",
    path: "/Settings/",
    icon: <img src={SettingIcon} height={"30px"}/>,
    iconClosed: <KeyboardArrowRightIcon />,
    iconOpened: <KeyboardArrowDownIcon />,
    subNav: [
      
      {
        title: "About Us",
        path: "/Settings/AboutUs/",
      },
      
      {
        title: "Privacy Policy",
        path: "/Settings/PrivacyPolicy/",
      },
      {
        title: "Terms & Conditions",
        path: "/Settings/TermsConditions/",
      },
      {
        title: "Refund Policy",
        path: "/Settings/RefundPolicy/",
      },
     
    
    ].filter(e=>e),
  },
].filter(e=>e);}
