

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Subscriptions from '../components/Subscription/Subscriptions';
import Layout from '../components/Layout/Layout';

function SubscriptionsModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Subscriptions' element={<Layout children={Subscriptions()}/>}/>


      </Routes>
    </div>
  );
}

export default SubscriptionsModule;