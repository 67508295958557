import React, { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styles from "./../../styles/form.module.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { RichTextEditor } from "@mantine/rte";
import { IMAGE } from "../../api";
import imageJPG from "./../../images/file1.png";
import Autocomplete from "@mui/material/Autocomplete";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import dayjs from 'dayjs';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const { beforeToday } = DateRangePicker;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function Form({
	formStructure,
	formTitle,
	setForm,
	form,
	isEdit,
	handleSubmit,
	isMulti,
	errorMessage, setAllowNext
}) {

	const [openAdError, setOpenAdError] = useState(false)
	const [content, setPopupContent] = useState("")
	const handleClose = () => {


		setOpenAdError(false);
	};
	const lengthOfInputContainerLG = isMulti
		? formStructure?.length < 4
			? 12 / formStructure?.length
			: 6
		: formStructure?.length < 4
			? 12 / (formStructure?.length - 1)
			: 6;

	// const handleChange = (event) => {
	// 	setForm({ ...form, [event.target.name]: event.target.value });
	// };
	function dateToYMD(date) {
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
	}
	function makePassword(length) {
		let result = "";
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	}
	const button = formStructure[formStructure.length - 1];

	function getDateFromHours(time) {

		time = time.split(":");
		let now = new Date();
		return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...time);
	}
	const [lockedInputs, setLockedInputs] = useState();
	useMemo(() => {
		const temp = {};
		formStructure.map((ele) => {
			if (ele?.type == "lockedInput")
				temp[ele?.name] = true;
		});
		setLockedInputs({ ...temp })
	}, [formStructure]);
	const handleLockedinput = (name) => {
		const temp = lockedInputs
		temp[name] = !temp[name]
		setLockedInputs({ ...temp })
	}
	const [allowSubmit, setAllowSubmit] = useState(true)
	const handleFormSubmit = (e) => {
		var requiredFieldCount = 0
		var requiredFieldFilledCount = 0
		formStructure.map((value) => {
			// console.log(form, "test 06-08-2023")
			if ((value.required && value.display != "none")) {
				requiredFieldCount = requiredFieldCount + 1
				if (Object.keys(form).includes(value.name) && form[value.name] != "" && form[value.name] != [] && form[value.name] != null && form[value.name]) {
					requiredFieldFilledCount = requiredFieldFilledCount + 1
				}
			}
		})
		if (requiredFieldFilledCount == requiredFieldCount) {
			handleSubmit(e)
			setAllowSubmit(true)
			// setSub(true)
		}


		else
			setAllowSubmit(false)
		// setSub(false)
		// allowSubmit && handleSubmit(e)
		// setSub(!sub)
	}
	return (
		<>	 <Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={openAdError}
			autoHideDuration={1500}
			onClose={handleClose}


		>
			<Alert severity="info" variant="filled" color="success">{content}</Alert>
		</Snackbar>
			<Card
				sx={{
					boxShadow: isMulti
						? "none"
						: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
					borderRadius: "10px",
					p: "25px 20px 15px",
					mb: "15px",
					color: "black",

					backgroundColor: "#f8fcff",
					position: "relative",
				}}
			>
				<Typography
					as="h3"
					sx={{
						fontSize: 18,
						fontWeight: 500,
						mb: "15px",
					}}
				>
					{formTitle}
				</Typography>

				<Box
					component="form"
					sx={{ minHeight: isMulti ? "30vh" : "40vh" }}
				// onSubmit={handleSubmit}
				>
					<Grid
						container
						alignItems="flex-start"
						key={"container-grid"}
						spacing={3}
					>
						{formStructure.map((value, index) => {
							if (value.type == "inputBox") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={value?.size || lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<TextField
											autoComplete={value.title}
											name={value.name}
											fullWidth
											id={value.id}
											type={value.variant || "text"}
											required={value?.required}
											value={form?.[value.name] || value?.default || ""}
											label={value.title}
											helperText={(((isMulti && errorMessage) || !allowSubmit) && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field" || (value?.endsWith && (!form?.[value.name]?.endsWith(value?.endsWith) && (value?.errorText || "Incorrect format")))}
											onChange={(event) => {
												if (value.regex) {
													if (
														event.target.value !== "" &&
														!value.regex.test(event.target.value)
													) {
														return;
													}
												}
												setForm({
													...form,
													[event.target.name]: value.isCaps
														? event.target.value.toUpperCase()
														: value?.isCapitalise
															? event.target.value.charAt(0).toUpperCase() +
															event.target.value.slice(1)
															: event.target.value,
												});
											}}
											onChangeCapture={(event) => {
												if (value.variant == "email") {
													!validator.isEmail(event.target.value)
														? event.target.setCustomValidity("Invalid Email")
														: event.target.setCustomValidity("");
												}
											}}
											InputProps={{
												style: {
													borderRadius: 8,
													backgroundColor: value.disabled && "#f2f2f2",
												},
												inputProps: {
													min: value?.min,
													max: value?.max,
													step: value?.step || 1,
													maxLength: value?.maxLength,
												},
											}}
											disabled={value.disabled || false}
										/>
										{(value.title == "Description" ||value.title == "Name")   && <div style={{ display: "flex", justifyContent: "flex-end" }}>
											<p>{form?.[value.name]?.length}/{value?.maxLength || "200"} {form?.[value.name]?.length >= value?.maxLength&& <span style={{color:"red"}}>Maximum limit reached</span>}</p>
										</div>}
									</Grid>
								);
							}
							if (value.type == "lockedInput") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<TextField
											autoComplete={value.title}
											name={value.name}
											fullWidth
											id={value.id}
											type={value.variant || "text"}
											required={value?.required}
											value={form?.[value.name] || ""}
											label={value.title}
											disabled={lockedInputs?.[value.name]}
											onChange={(event) => {
												setForm({
													...form,
													[event.target.name]: event.target.value,
												});
											}}
											helperText={(((isMulti && errorMessage) || !allowSubmit) && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field" || value?.endsWith && (!form?.[value.name]?.endsWith(value?.endsWith) && (value?.errorText || "Incorrect format"))}
											InputProps={{
												endAdornment: (
													<IconButton color="secondary" onClick={() => handleLockedinput(value.name)}>
														{lockedInputs?.[value.name] ? <LockOutlinedIcon

														/> : <LockOpenOutlinedIcon

														/>}
													</IconButton>
												),
											}}
										/>
									</Grid>
								);
							}
							if (value.type == "password") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<TextField
											autoComplete={value.title}
											name={value.name}
											fullWidth
											id={value.id}
											type={value.variant || "text"}
											required={value?.required}
											value={form?.[value.name] || ""}
											label={value.title}
											onChange={(event) => {
												setForm({
													...form,
													[event.target.name]: event.target.value,
												});
											}}
											InputProps={{
												endAdornment: (
													<IconButton color="secondary">
														<RestartAltIcon
															onClick={(e) => {
																setForm({
																	...form,
																	[value?.name]: makePassword(10),
																});
															}}
														/>
													</IconButton>
												),
											}}
										/>
									</Grid>
								);
							}

							if (value.type == "duration") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={value?.size || lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimeField
												label={value?.title}
												name={value?.name}
												value={
													typeof form?.[value.name] == "string"
														?
														dayjs(getDateFromHours(form?.[value.name]))

														: (form?.[value.name] || "")
												}
												onChange={(newValue) => {
													console.log(newValue)

													setForm({
														...form, [value?.name]: new Date(newValue)?.getHours() + ":" + new Date(newValue)?.getMinutes() + ":" + new Date(newValue)?.getSeconds()
														// &&(newValue?.getHours()+":"+newValue?.getMinutes()+":"+newValue?.getSeconds()) 
													})
												}
												}
												format="HH:mm:ss"
												placeholder="00:00:00"
											/>

										</LocalizationProvider>
									</Grid>
								);
							}
							if (value.type == "phone") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<FormControl fullWidth>
											<PhoneInput
												labelId="demo-simple-select-label"
												country={"in"}
												required={value?.required}
												value={form?.[value.name]}
												onChange={(phone, countryCode) => {
													setForm({
														...form,
														[value.name]:
															form?.countryCode == "+" + countryCode.dialCode
																? ((countryCode.dialCode == "91" && phone[2] >= 6) ? phone : countryCode.dialCode)
																: countryCode.dialCode,
														countryCode: "+" + countryCode.dialCode,
													});
													console.log(form)
												}}
												isValid={(value, country) => {
													if (country.dialCode == "91" && value[2] < 6) {
														return 'Invalid Number'
													} else {
														return true;
													}
												}}
												dropdownClass={styles.phoneInputDropDown}
												buttonClass={styles.phoneInputButton}
												inputClass={styles.phoneInput}
											/>
										</FormControl>
									</Grid>
								);
							}

							if (value.type == "start_date_end_date") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<FormControl fullWidth>
											<DateRangePicker
												size="lg"
												placeholder={value.title}
												ranges={[]}
												name={value.name}
												required={value?.required}
												value={form?.[value.name] || ""}
												format="dd-MM-yyyy"
												disabledDate={beforeToday()}
												onChange={(range) => {


													setForm({
														...form,
														[value.name]: range,
														[value.formName1]: dateToYMD(range[0]),
														[value.formName2]: dateToYMD(range[1]),
													});
												}}
												defaultCalendarValue={[new Date(), new Date()]}
											/>
										</FormControl>
									</Grid>
								);
							}

							if (value.type == "select") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={value?.size || lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<FormControl fullWidth>
											{/* <InputLabel id="demo-simple-select-label">
												{" "}
												{value.title}
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												name={value.name}
required={value?.required}
												label={value.title}
												value={form?.[value.name] || ""}
												onChange={(event) => {
		setForm({ ...form, [event.target.name]: event.target.value });
	}}
												defaultValue=""
												
											>
											
												{value.options.map((option, index) => (
													<MenuItem value={option?.value||option} key={index}>
														{option?.label||option}
													</MenuItem>
												))}
											</Select> */}
											<Autocomplete

												// ListboxProps={{ style: { maxHeight: 150, overflow: 'auto' } }}
												id="demo-simple-select-label"
												options={value.options}
												name={value.name}
												isOptionEqualToValue={(option, value) =>
													option.value === value.value
												}
												label={value.title}
												value={form?.[value.name] || ""}
												onChange={(event, newValue) => {
													setForm({
														...form,
														[value.name]: newValue?.value || newValue,
													});
												}}
												defaultValue={value?.defaultValue}
												renderInput={(params) => (
													<TextField
														{...params}
														label={value.title}
														required={value?.required}
														helperText={(((isMulti && errorMessage) || !allowSubmit) && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}
													/>
												)}
											/>
										</FormControl>
									</Grid>
								);
							}

							if (value.type == "select_multiple") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}

										lg={lengthOfInputContainerLG}
										display={value.display || "block"}
									>
										<FormControl fullWidth required={value?.required}>
											{/* <InputLabel id="demo-multiple-checkbox-label">
											{value.title}
											</InputLabel>
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												name={value.name}

												value={form?.[value.name]|| []}
												onChange={(event) => {
													const {
														target: { value },
													} = event;
													setForm({
														...form,
														[event.target.name]:
															// On autofill we get a stringified value.
															typeof value === "string"
																? value.split(",")
																: value,
													});
												}}
												input={<OutlinedInput label={value.title} />}
												renderValue={(selected) => selected.join(", ")}
												MenuProps={MenuProps}
											>
												{value.options.map((option, index) => (
													<MenuItem key={index} value={option}>
														<Checkbox
															checked={form?.[value.name]?.indexOf(option) > -1}
														/>
														<ListItemText primary={option} />
													</MenuItem>
												))}
											</Select> */}
											<Autocomplete
												multiple
												id="tags-outlined"
												name={value.name}
												// ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
												options={value.options}
												getOptionLabel={(option) => option}
												onChange={(event, newValue) => {
													setForm({ ...form, [value.name]: newValue });

												}}
												value={form?.[value.name] || []}
												filterSelectedOptions
												getOptionDisabled={(option) => (form?.[value.name]?.length == (value?.maxSelections || 10) || form?.[value.name]?.includes(option) ? true : false)}
												renderInput={(params) => (
													<TextField
														{...params}

														label={value.title}
														helperText={(form?.[value.name]?.length == (value?.maxSelections || 10) && (value?.errorText || "Maximum number of selections have been made.")) || (((isMulti && errorMessage) || !allowSubmit) && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}
														placeholder={value.title}
														inputProps={{
															...params.inputProps,
															required: form?.[value.name]?.length === 0
														}}
														required={value?.required}
													/>
												)}
											/>
										</FormControl>
									</Grid>
								);
							}

							if (value.type == "switch") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={lengthOfInputContainerLG}
										marginTop={"12px"}
										container
										direction="row"
										alignItems={"center"}
										display={value.display || "block"}
									>
										<FormControlLabel
											label={value.title}
											labelPlacement="start"
											control={
												<Switch
													defaultChecked
													name={value.name}
													required={value?.required}
													onChange={(event) => {
														setForm({
															...form,
															[event.target.name]: event.target.value,
														});
													}}
												/>
											}
										/>
									</Grid>
								);
							}
							if (value.type == "toggle") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={value?.size || lengthOfInputContainerLG}
										container
										direction="row"
										alignItems={"center"}
										display={value.display || "block"}
									>
										{/* <FormControlLabel
											label={value.title}
											name={value.name}
required={value?.required}
											labelPlacement="start"
											control={
												<ToggleButtonGroup
													value={form?.[value.name] || ""}
													exclusive
													style={{ paddingLeft: "20px" }}
													onChange={(event) => {
														setForm({ ...form, [event.target.name]: event.target.value });
													}}
													name={value.name}
required={value?.required}
													aria-label={value.title}
												>
													{value.options.map((option, index) => (
														<ToggleButton
															value={option.value}
															key={index}
															name={value.name}
required={value?.required}
															color={option.color}
														>
															{option.value}
														</ToggleButton>
													))}
												</ToggleButtonGroup>
											}
										/> */}
										<FormControl>
											<FormLabel id="demo-row-radio-buttons-group-label">
												{value.title}
											</FormLabel>
											<RadioGroup
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												name="row-radio-buttons-group"
											>
												{value.options.map((option, index) => (
													<FormControlLabel
														value={option.value}
														name={value.name}
														required={value?.required}
														onChange={(event) => {
															setForm({
																...form,
																[event.target.name]: event.target.value,
															});

														}}
														control={
															<Radio
																color={option.color}
																checked={
																	form?.[value.name] != undefined
																		? form?.[value.name] == option.value
																		: option.value == value?.default
																}
															/>
														}
														label={option.value}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</Grid>
								);
							}
							if (value.type == "description") {
								return (
									<Grid item xs={12} md={12} lg={12} key={index + "-grid"}>
										<Typography
											as="h5"
											sx={{
												fontWeight: "500",
												fontSize: "14px",
												mb: "12px",
											}}
										>
											{value.title}
											{value.required && (
												<span style={{ color: "red", fontSize: "16px" }}>
													{" "}
													*
												</span>
											)}
										</Typography>

										<RichTextEditor
											id="rte"
											value={form?.[value.name]}

											onChange={(content) =>


												setForm({ ...form, [value.name]: content })
											}
											controls={[
												["bold", "italic", "underline"],
												["unorderedList", "h1", "h2", "h3", "h4"],
												["sup", "sub"],
												["alignLeft", "alignCenter", "alignRight"],
											]}
										/>
										<div style={{ display: "flex", justifyContent: "flex-end" }}>
											<p>{form?.[value.name]?.length}/{value?.limit || "200"}</p>
										</div>
										<p style={{ fontSize: "10px", color: "red", width: "100%" }}>{!allowSubmit && ((form[value.name] == "" || form[value.name] == [] || form[value.name] == null || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}</p>
										<p style={{ fontSize: "10px", color: "red", width: "100%" }}>{(isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}</p>
									</Grid>
								);
							}
							if (value.type == "image") {
								return (
									// <Grid
									// 	item
									// 	xs={12}
									// 	md={12}
									// 	key={index + "-grid"}
									// 	lg={lengthOfInputContainerLG}
									// 	display={value.display || "block"}
									// >
									// 	{form?.[value.name] ? (
									// 		<div className={styles.imageDisplay}>
									// 			<img src={typeof(form?.[value.name])=="string"?IMAGE+form?.[value.name]:URL.createObjectURL(form?.[value.name])} />
									// 			<CloseIcon
									// 				onClick={() => setForm({ ...form, [value.name]: "" })}
									// 			/>
									// 		</div>
									// 	) : (
									// 		<div className={styles.imageInput}>
									// 			<label for={index + "image"} className="btn">
									// 				{value.title}
									// 				<span style={{fontSize:"9px",color:"red"}}>{value.subtitle}</span>
									// 			</label>
									// 			<input
									// 				type="file"
									// 				id={index + "image"}
									// 				name={value.name}
									// required={value?.required}
									// 				placeholder="Choose Image"
									// 				style={{ visibility: "hidden" }}
									// 				onChange={(event) =>
									// 					event.target.files[0] &&
									// 					setForm({
									// 						...form,
									// 						[event.target.name]: event.target.files[0],
									// 					})
									// 				}
									// 			/>
									// 		</div>
									// 	)}
									// </Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										lg={4}
										xl={value?.size || 2}
										key={index + "-grid"}
										display={value.display || "block"}
									>
										<label for={index + "image"} className="btn">
											{" "}
											<Box
												sx={{
													background: "#F3F6F9",
													borderRadius: "10px",
													padding: "10px 5px",
													paddingBottom: "0px",
													textAlign: "center",
												}}
												className="dark-BG-101010"
											>
												<img
													src={
														form?.[value.name]
															? typeof form?.[value.name] == "string"
																? IMAGE + form?.[value.name]
																: URL.createObjectURL(form?.[value.name])
															: value?.image || imageJPG
													}
													alt="Icon"
													style={{ borderRadius: "10px" }}
													height={value?.imageHeight || "150px"}
												/>
												<Typography mt={1} fontWeight="500" fontSize="13px">
													{value?.title}
												</Typography>
												{value?.subtitle && <>
													<span style={{ fontSize: "9px", color: "red" }}>
														{value?.subtitle}
													</span>
													<br /></>}
												{value?.subsubtitle && <>
													<span style={{ fontSize: "9px", color: "red" }}>
														{value?.subsubtitle}
													</span>
													<br /></>}
												{value?.subsubsubtitle && <>
													<span style={{ fontSize: "9px", color: "red" }}>
														{value?.subsubsubtitle}
													</span>	<br /></>}
												{/* {(Math.round((form?.[value.name]?.size / 1024))>1024)&&
											<>
												<span style={{ fontSize: "10px", color: "red" }}>
											File size should not be more than 1 MB.
												</span><br /></>
												(
													dispatch({ type: MESSAGE, payload:{Message:"File Size too big. Please choose a different file"} })
												setTimeout(()=>{
													console.log(form,value.name)
													delete form?.[value.name]
												},5000)
												
												
						)
						}	 */}
												{/* {typeof(form?.[value.name])!="string"&&form?.[value.name]&&(!["image/jpeg", "image/png", "image/jpg", "text/srt"].includes(form?.[value.name]?.type))&&
											<>
											<span style={{ fontSize: "10px", color: "red" }}>
												Please Upload Valid file Format
											</span><br /></>
											(
												dispatch({ type: MESSAGE, payload:{Message:"File Size too big. Please choose a different file"} })
											setTimeout(()=>{
												console.log(form,value.name)
												delete form?.[value.name]
											},5000)
											
											
					)
					} */}


												<input
													type="file"
													id={index + "image"}
													name={value.name}
													required={value?.required && !isEdit}

													placeholder="Choose Image"
													style={{
														visibility: "hidden",
														position: "relative",
														zIndex: "10",
														height: "100%",
													}}
													accept={value?.accept || ".jpeg,.png,.jpg"}
													onClick={(event) => {

														event.target.value = []
													}}
													onChange={(event) => {

														console.log(event.target.files)
														if ((Math.round((event.target.files[0]?.size / 1024)) > 1024)) {

															setOpenAdError(true)
															setPopupContent("File size should not be more than 1 MB.")
															// if(isMulti)
															// {
															// 	setAllowNext(false)

															// }
															// setAllowSubmit(false)
														}
														else if (!(["image/jpeg", "image/png", "image/jpg", "text/srt", "application/pdf"].includes(event.target.files[0]?.type))) {

															setOpenAdError(true)
															setPopupContent("Please Upload Valid file Format")

														}
														else {

															// if(isMulti)
															// setAllowNext(true)
															// setAllowSubmit(true)
															setForm({
																...form,
																[event.target.name]: event.target.files[0],
															})
														}
													}
													}
												/>
											</Box>
										</label>
										<p style={{ fontSize: "10px", color: "red", width: "100%", textAlign: "center" }}>{!allowSubmit && ((form[value.name] == "" || form[value.name] == [] || form[value.name] == null || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}</p>
										<p style={{ fontSize: "10px", color: "red", width: "100%", textAlign: "center" }}>{(isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}</p>
									</Grid>
								);
							}
							if (value.type == "undefined") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={lengthOfInputContainerLG}
										container
										direction="row"
										alignItems={"center"}
										display={value.display || "block"}
									></Grid>
								);
							}
							if (value.type == "specialElement") {
								return (
									<Grid
										item
										xs={12}
										md={12}
										key={index + "-grid"}
										lg={12}
										container
										direction="row"
										alignItems={"center"}
										display={value.display || "block"}
									>
										{value.element}
									</Grid>
								);
							}
							if (value.type == "button" && value.forceShow || !isMulti) {
								return (
									<Grid
										item
										xs={value?.size || 12}
										lg={value?.size}
										key={index + "-grid"}
										textAlign={value?.align || "center"}
										display={value.display || "block"}
									>
										<Button
											type={value.forceShow && "button"}
											variant="contained"
											style={{
												background:
													"linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
											}}
											sx={{
												mt: 1,
												textTransform: "capitalize",
												borderRadius: "8px",
												fontWeight: "500",
												fontSize: "13px",
												padding: value?.padding || "12px 20px",
												minWidth: "0",
												minHeight: "0",
												color: "#fff !important",
											}}
											onClick={(e) => value.forceShow ? value?.handleClick() : handleFormSubmit(e)}
										>
											{value.forceShow
												? value.title
												: isEdit
													? "Update"
													: value.title}
										</Button>
									</Grid>
								);
							}
						})}
					</Grid>
				</Box>

			</Card>
		</>
	);
}
