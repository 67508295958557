import React from "react";
import { useState, useEffect, useMemo } from "react";

import ListTable from "../utils/Table";
import { all_transaction_list } from './../../actions/transaction';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import Export from "./../utils/Export"
export default function Transaction() {
	const dispatch = useDispatch()
	const location = useLocation()

	const [isEdit, setIsEdit] = useState(false);

	const [form, setForm] = useState({});
	const [tableData, setTableData] = useState({
		tableTitle: "Transactions",
		column_sum: {
			name: "payment_amount",
			title: "Total Amount"
		},
		disableDelete: true,
		amount:"35",
		tableHead: [
			{
				id: "name",
				label: "Name",
				link: "/Customer/CustomerDetail/CustomerDetail",
				color: "var(--gradientColor1)"
			},
			{
				id: "email",
				label: "Customer Info",
				subText: "mobile_no"
			},
			{
				id: "plan",
				label: "Plan",
				isSpecial: true,
				align: "left"
			},
			{
				id: "payment_amount",
				label: "Amount",
			},
			{
				id: "payment_id",
				label: "Payment ID",
				subText: "payment_method"
			},
			{
				id: "date",
				label: "Date",
			},

			{
				id: "device_type",
				label: "Device Type",
			},

			{
				id: "payment_status",
				label: "Status",
				isButtonDisplay: true
			},
		],
		tableBody: [

		],
		filterColumn: [
			{
				id: "1",
				title: "Plan",
				name: "plan1",
				// default:location?.state,
				options: ["Silver", "Gold", "Platinum"],

			},
			{
				id: "1",
				title: "Payment Status",
				name: "payment_status",
				options: ["Success", "Failed", "Expired"],
			}
		],
		isDateRangeFilter: "date"
	});



	const transactions = useSelector((state) => state.transactions.transactions);
	useEffect(() => {
		// dispatch(all_transaction_list({id:0}))
		dispatch(all_transaction_list())
	}, [])
	
	useMemo(() => {

		if (transactions?.statuscode == 200 && tableData.tableBody[tableData.tableBody.length - 1]?.idAPILOOP!=transactions?.data[transactions?.data.length - 1]?.id) {
			const temp = tableData
			var temp1 = temp.tableBody
			temp.tableBody= temp.tableBody.concat(transactions?.data.map((ele) => ({
				...ele, id: ele?.user?.id, payment_status: ele?.status, name: ele?.user?.firstName + " " + ele?.user?.lastName,mobile_no:ele?.mobile_no != null ?ele?.mobile_no :" - " ,
				idAPILOOP:ele?.id,
				plan: ele?.transaction_type == "SVOD" ? (
					<p>{ele?.plan?.plan_name}</p>
				) : (
					<><p  style={{color:"var(--gradientColor2)"}}>{ele?.transaction_type}</p>
						<span>({ele?.movie?.movie_name})</span></>
				),
				plan1 : ele?.plan?.plan_name
			})))
			console.log(temp.tableBody[temp.tableBody.length - 1]?.idAPILOOP,transactions?.data[transactions?.data.length - 1]?.id)
			// temp.tableBody=temp1
			setTableData({ ...temp })
			// if(transactions?.isFull=="False" ){
				
				
			// 	dispatch(all_transaction_list({id:transactions?.data[transactions?.data.length - 1]?.id}))
			
			// }
		
			
		}
	}, [transactions])
console.log(transactions,"parth")
	// tableData.amount =transactions?.total_amout 



	return (
		<>



			<Export fileName={"Transactions"} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />
			<ListTable
				tableData={tableData}
				key={"ListTable"}
				setTableData={setTableData}
				setForm={setForm}
				setIsEdit={setIsEdit}
			/>

		</>
	);
}
