
export const FormStructure = (isEdit) => {


    return [
        [



            {
                id: "1",
                type: "select",
                title: "Video Category",
                name: "movie_category",
                options: ["Portrait", "Landscape"],
                required: true
            },
            {
                id: "2",
                type: "select",
                title: "Sub Category",

                name: "movie_subcategory",
                options: ["Portrait", "Landscape"],
                required: true
            }
            ,
            {
                id: "3",
                type: "select",
                title: "Access",
                name: "movie_access",
                options: ["FREE", "SVOD" ,"TVOD"],
                required: true
            }
            ,
            {
                id: "4",
                type: "inputBox",
                title: "Name",
                name: "movie_name",

                maxLength: "25",
                // regex: /^[a-zA-Z0-9\s\&]+$/,
                isCapitalise: true,
                required: true
            }
            ,
            {
                id: "5",
                type: "select",
                title: "Language",
                name: "movie_language",
                options: ["English", "Hindi", "Gujarati"],
                required: true
            }
            ,
            {
                id: "6",
                type: "select_multiple",
                title: "Select Genre",
                name: "movie_genre",
                maxSelections: "3",
                errorText: "You can select max 3 genre at a time",
                options: ["Portrait", "Landscape"],
                required: true

            },

            {
                id: "8",
                type: "select_multiple",
                title: "Select Cast",
                name: "movie_cast",
                maxSelections: "7",
                errorText: "You can select max 7 cast at a time",
                options: ["Portrait", "Landscape"],
                // required: true

            },

            {
                id: "9",
                type: "select",
                title: "Publish Type",
                name: "publish_type",
                options: ["Now", "Later"],
                required: true

            },
            {
                id: "10",
                type: "inputBox",
                variant: "time",
                title: "Publish Time",
                name: "publish_time",
                display: "none",
                required: true
            },
            {
                id: "10",
                type: "inputBox",
                variant: "date",
                title: "Publish Date",
                min: new Date().toISOString().split('T')[0],
                name: "release_date",
                display: "none",
                required: true
            },
            {
                id: "10",
                type: "inputBox",
                title: "Rental Price",
                name: "rental_price",
                regex: /^[0-9\.]+$/,
                maxLength: "6",
                display: "none",
                required: true
            },
            {
                id: "10",
                type: "inputBox",
                variant: "number",
                title: "Tax (18.5%)",
                name: "tax",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "10",
                type: "inputBox",
                title: "Streaming Fees (INR)",
                name: "streaming_fees",
                regex: /^[0-9\.]+$/,
                maxLength: "5",
                display: "none",
                required: true
            },
            {
                id: "10",
                type: "inputBox",
                variant: "number",
                title: "Amount Received",
                name: "amount_received",
                disabled: true,
                display: "none",
                required: true
            },

            {
                id: "10",
                type: "inputBox",
                title: "Available For (Days)",
                name: "available_for_days",
                regex: /^[0-9]+$/,
                maxLength: "2",
                display: "none",
                required: true
            },
            {
                id: "4",
                type: "inputBox",
                title: "Description",
                name: "movie_description",
                maxLength: "200",
                size:"12",
                isCapitalise: true,
                required: true
            },
        ],
        [
            {
                id: "12",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution: 360p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_360p",
                required: true
            },
            {
                id: "13",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 480p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_480p",
                required: true
            },
            {
                id: "14",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 720p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_720p",
                required: true
            },
            {
                id: "15",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 1080p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_1080p",
                required: true
            },
            {
                id: "15",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Upload Movie Mp4 URL",
                endsWith: ".mp4",
                errorText: "File extension should be .mp4 only",
                name: "movieMp4_URL",
                required: true
            },
            {
                id: "15",
                type: "inputBox",
                title: "Duration",
                name: "movie_duration",
                disabled: true,
                required: true
            },
            {
                id: "16",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Trailer URL ( .m3u8)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movie_trailer",
                // required: true
            },
            {
                id: "17",
                type: "lockedInput",
                variant: "number",
                title: "Set Position",
                name: "set_position",
                min: "0",
                max: "99",
                required: true
            },
            {
                id: "21",
                type: "toggle",
                title: "Is This Video 18+ ?",
                name: "movie_is_for18Plus",
                default: "No",
                size: "12",

                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }]

            },
            // { type: "undefined" },
            {
                id: "18",
                type: "image",
                title: "Browse Thumbnail",
                subtitle: "(Resolution : 910px x 1280px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                name: "movie_thumbnail",
                required: true
            }, {
                id: "19",
                type: "image",
                subtitle: "(Resolution : 1280px x 720px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                title: 'Browse Poster',
                name: "movie_poster",
                size: 4,
                required: true
            },
        ],



    ]
}