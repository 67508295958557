
export const FormStructure = () => {
	return[
	
		
		{
			id: "1",
			type: "inputBox",
			title: "Series Name",
			name: "series_name",
			maxLength: "25",
			regex: /^[a-zA-Z0-9\s\&]+$/,
			isCapitalise: true,
			required: true,
		},
		{
			id: "2",
			type: "select",
			title: "Select Season",
			name: "season",
			options: ["Season 1", "Season 2", "Season 3", "Season 4"],
			required: true,
		},
		
       
		{
			id: "3",
			type: "select",
			title: "Language",
			name: "language",
			options: ["English", "Hindi", "Gujarati"],
			required: true,
		},
		{
			id: "4",
			type: "select_multiple",
			title: "Select Genre",
			name: "genre",
			maxSelections:"3",
        errorText:"You can select max 3 genre at a time",
			options: ["Portrait", "Landscape"],
			required: true,
		},
		{
			id: "3",
			type: "select",
			title: "Show Access",
			name: "series_type",
			options: ["FREE","SVOD"],
			required: true,
		},
		{
			id:"10",
			type:"inputBox",
			title:"Rental Price",
			name:"rental_price",
			regex:/^[0-9\.]+$/,
			maxLength:"6",
			display:"none",
			required:false
		},
		{
			id:"16",
			type:"inputBox",
			title:"Trailer URL ( .m3u8)",
			endsWith:".m3u8",
			errorText:"File extension should be .m3u8 only",
			name:"series_trailer",
			required:true
		}, 
        {
			id: "2",
			type: "toggle",
			title: "First Episode Free",
			name: "first_episode_free",
			size:"3",
			options: [
				{ value: "Yes", color: "success" },
				{ value: "No", color: "danger" },
			],
			required: true,
		},
		
		{
			id: "7",
			type: "description",
			title: "Description",
			name: "series_description",
			required: true,
		},
		// {
		// 	id: "5",
		// 	type: "image",
		// 	title: "Browse Thumbnail",
		// 	subtitle: "(Resolution : 910 x 1280)",
		// 	subsubtitle:"Max File Size 1MB",
		// 	subsubsubtitle:"Support only JPG,PNG,JPEG",
		// 	name: "series_thumbnail",
		// 	required:true
		// },
		{
			id: "6",
			type: "image",
			title: "Browse Poster",
			subtitle: "(Resolution : 1280 x 720)",
			subsubtitle:"Max File Size 1MB",
			subsubsubtitle:"Support only JPG,PNG,JPEG",
			size: "4",
			name: "series_poster",
			required:true
		},
		{
			id: "7",
			type: "button",
			title:  "Create",
		},

];}
