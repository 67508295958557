import React from "react";
import { useState, useMemo,useEffect } from "react";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import { episode_create,episode_delete,episode_update,all_episode_list } from '../../../actions/WebSeries/episode';
import { all_series_list } from '../../../actions/WebSeries/series';
import { all_cast_list } from '../../../actions/Masters/cast';
import { useDispatch,useSelector } from 'react-redux';
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import Export from "../../utils/Export";
export default function Episode() {
	const user=useSelector((state) => state.layout.profile)
	const dispatch=useDispatch()
	const location = useLocation();
	const [tableData,setTableData]=useState({...TableData(),deleteRecord:episode_delete,updateRecord:episode_update,
		onDeleteText:"Are you sure want to delete?",
		onUpdateText:"Are you Sure?",})
	const [form, setForm] = useState({});
	
	const formTitle = ["Episode Details","Episode Resolution"].filter(e=>e)
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	const tempFormStruct=FormStructure(isEdit)
	const [formStructure, setFormStructure] = useState([...tempFormStruct]);
	useEffect(() => {
		setView("view_all");
		setForm({subtitles:"Inactive",episode_is_for18Plus:"No",allow_download:"Yes"})
	}, [location]);
	
	
	
    const episodes = useSelector((state) => state.webseries.episodes);

    useEffect(()=>{
		const data=new FormData()
			data.append('id',user?.id)
      dispatch(all_episode_list(data))
	  dispatch(all_series_list())
	  dispatch(all_cast_list())
    },[user])
	const series = useSelector((state) => state.webseries.series);
  
	// console.log(series,"000000")
	
	useMemo(() => {
		if(isEdit) {
			setView("create_new")
			
	 }else
	  {setView("view_all");
	
	  setForm({subtitles:"Inactive",episode_is_for18Plus:"No",allow_download:"Yes"})}
	  const temp=formStructure
		tempFormStruct.map((arr,i)=>arr.map((ele,j)=>!["select","select_multiple"].includes(ele.type)&&(temp[i][j]=ele)))
		
		setFormStructure([...temp])
	}, [isEdit]);
	
const casts = useSelector((state) => state.masters.casts);
	useMemo(()=>{
		
		if(series?.statuscode==200){
		const temp=formStructure
		temp[0][0]["options"]=series?.data.map((series)=>(series?.series_name))
		
		setFormStructure([...temp])
		
		
	
	}
	if(series?.statuscode==200){
		const tempTableData=tableData
		tempTableData.filterColumn[0]["options"]=series?.data.map((series)=>(series?.series_name))
	
	setTableData({...tempTableData})}
	},[series])
	
	useMemo(()=>{
		
		if(casts?.statuscode==200){
		const temp=formStructure
		temp[0][8]["options"]=casts?.data.map((cast)=>(cast?.cast_name))
		
		setFormStructure([...temp])
		
		
	
	}
	},[casts])


	useMemo(()=>{
		if(form?.publish_type=="Later")
		{
			
			const temp=formStructure
			temp[0][6]["display"]="block"
			temp[0][7]["display"]="block"
			
			setFormStructure([...temp])
		}
		else{
			
			const temp=formStructure
			temp[0][6]["display"]="none"
			temp[0][7]["display"]="none"
			setFormStructure([...temp])
		}
	},[form?.publish_type])
    useMemo(()=>{
      
      if(episodes?.statuscode==200){const temp=tableData
		
      temp.tableBody=episodes?.data.map((ele,index)=>({...ele,uploaded_by:ele?.series_name?.created_by?.firstName,series_ownership:ele?.series_name?.series_ownership,series_name:ele?.series_name?.series_name,series_cast:ele?.series_cast.map((cast)=>cast.cast_name)}))
      setTableData({...temp})}
    },[episodes])
	const message = useSelector((state) => state.layout.message);
	
	const handleSubmit = (event) => {
		event.preventDefault();
		
		const tempForm=form
		tempForm["tempseries_name"]=form?.["series_name"]
		tempForm["tempseries_cast"]=form?.["series_cast"]
		tempForm["series_name"]=series?.data.map((option)=>form?.["series_name"]?.includes(option.series_name)&&option.id).filter(e=>e)
		tempForm["series_cast"]=casts?.data.map((option)=>form?.["series_cast"]?.includes(option.cast_name)&&option.id).filter(e=>e)
		
		tempForm["uploaded_by"]=user?.id
		setForm({
			...tempForm
		});
		
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		
		formStructure.map((structure)=>structure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof(form?.[element.name])!="string") {
				const temp = form;
				temp["temp"+element.name] = form?.[element.name]
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		}));
		const temp = tableData;
		if (isEdit)
			{
		
		dispatch(episode_update(data))
		}
		else
		{	
			dispatch(episode_create(data))}
		
	};

	useMemo(()=>{
		if(message?.statuscode==200)
		{
			
		
		setIsEdit(false);
		setForm({subtitles:"Inactive",episode_is_for18Plus:"No",allow_download:"Yes"});
		setTimeout(()=>{const data=new FormData()
			data.append('id',user?.id)
      dispatch(all_episode_list(data))},1000)
      setView("view_all")}
		else{
			const tempForm=form
			console.log(tempForm)
			tempForm["series_name"]=form?.tempseries_name
			tempForm["series_cast"]=form?.tempseries_cast
			tempForm["episode_thumbnail"]=form?.tempepisode_thumbnail
			tempForm["episode_poster"]=form?.tempepisode_poster
			console.log(tempForm)
		setForm({...tempForm });
		}
	},[message])



	const videoEl = useRef(null);
	const handleLoadedMetadata = () => {
    const video = videoEl.current;
	var d = Number(video.duration);
	var h = Math.floor(d / 3600);
	var m = Math.floor((d % 3600) / 60);
	var s = Math.floor((d % 3600) % 60);

	var hDisplay = (h > 9 ? h : "0" + h) + ":";
	var mDisplay = (m > 9 ? m : "0" + m) + ":";
	var sDisplay = s > 9 ? s : "0" + s;

    setForm({...form,episode_duration:hDisplay + mDisplay + sDisplay})
   
  };
  

 
  

 
 

 


useMemo(()=>{
	var temp=form
	
	// if(isEdit)
	// {
	// 	var flag=true
	// 	for(var i=0;i<episodes?.data.length;i++)
	// 	{
	// 		if(episodes?.data[i]?.id==form?.id)
	// 		{
	// 			if(episodes?.data[i]?.series_name?.series_name==form?.series_name)
	// 		{
	// 			flag=false
				
	// 		}
	// 		break
	// 	}
	// 	}
		
	// 	if(flag)
	// 	{
	// 		temp={subtitles:"Inactive",episode_is_for18Plus:"No",allow_download:"Yes"}
	// 		temp['id']=form?.id
	// 		temp['series_name']=form?.series_name
			
	// 	}
	// }
	// else
	// {
	// 	temp={subtitles:"Inactive",episode_is_for18Plus:"No",allow_download:"Yes"}
	// 	temp['series_name']=form?.series_name
	// }
	
	series?.data.filter((series)=>{
		if(form?.series_name==series?.series_name)
	temp["series_type"]=series?.series_type})
	
	setForm({...temp})
	
},[form?.series_name])

	return (
		<>
		<video style={{display: "none"}} id="myvid" src={form?.episodeMp4_URL} ref={videoEl} type="video/mp4" onLoadedMetadata={handleLoadedMetadata}>
	
	</video>

			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				
				export_excel={
					<Export
						fileName={"TV Shows"}
					
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						headings={tableData.tableHead.map((value) => value.label)}
					/>
				}
			/>

			{view == "create_new" && (
				<MultiStepForm
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
			
		</>
	);
}
