


export const TableData= () => {
  
  return{
    tableTitle:"Videos",
    onDeleteText:"Are you Sure?",
    onUpdateText:"Are you Sure?",
    tableHead:[
        {
            id: 'movie_name',
            label: 'Name',
            link:"/Movie/MovieDetails",
            color:"var(--gradientColor1)",
            subText:"movie_subcategory"
          },
          {
            id: 'movie_poster',
            label: 'Image',
            isImage:true
          },
       
      
          {
            id: 'movie_access',
            label: 'Access',
          },
          ,
          {
            id: 'movie_language',
            label: 'Language',
          },
          ,
          {
            id: 'movieViews',
            label: 'Views',
          },
          {
            id: 'create_at',
            label: 'Created At',
          },
          
     

         {
            id: 'status',
            label: 'Status',
            
          },
        
          {
            id: 'edit',
            label: 'Update',
          },
    ].filter(e=>e),
    tableBody:[
       
    ],
    filterColumn:[
      {
        id: "1",
      title: "Access Type",
      name: "movie_access",
      options:["FREE","TVOD","SVOD"],
      },
      {
        id: "2",
      title: "Language",
      name: "movie_language",
      options:["English","Hindi","Gujarati"],
      },
      {
        id: "3",
      title: "Category",
      name: "movie_category",
      options:["Action","Comedy","Drama","Horror"],
      },
      {
        id: "3",
      title: "Sub Category",
      name: "movie_subcategory",
      options:["Action","Comedy","Drama","Horror"],
      displayOn:"movie_category"
      }
    ]
  }}