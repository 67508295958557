
import { useSelector } from "react-redux"

export const TableData= () => {
  return {
    tableTitle:"Upcoming Videos",
    tableHead:[
        {
            id: 'movie_name',
            label: 'Video Name',
          },
          {
            id: 'movie_poster',
            label: 'Image',
            isImage:true
          },
          {
            id: 'movie_subcategory',
            label: 'Sub Category',
          },
          
         
          {
            id: 'movie_language',
            label: 'Language',
          },
          {
            id: 'views',
            label: 'Views',
          }
         

          ,
          {
            id: 'release_date_display',
            label: 'Release Date',
            isSpecial: true,
            align:"left"
          }

          ,
          {
            id: 'edit',
            label: 'Update',
          },
    ],
    tableBody:[
        
    ],
    filterColumn:[
      
      {
        id: "2",
      title: "Language",
      name: "movie_language",
      options:["English","Hindi","Gujarati"],
      },
    
      {
        id: "4",
      title: "Video Category",
      name: "movie_category",
      options:["Admin","Distributor"],
      }
    ]
  }}