import * as actionType from "../constants/actionTypes";

const layoutReducer = (state = { layoutData: null }, action) => {
	switch (action.type) {
		case actionType.MESSAGE:
			return { ...state, message: action.payload };
			case actionType.LOGGEDIN:
				return { ...state, loggedin: action.payload };
			
			case actionType.PROFILE:
			return { ...state, profile: action.payload };
			
	
			
		default:
			return state;
	}
};

export default layoutReducer;
