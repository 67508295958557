import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
	sliderbanner_create,
	sliderbanner_delete,
	sliderbanner_update,
	all_sliderbanner_list,
} from "../../actions/sliderbanner";
import { all_movie_list } from "../../actions/Movie/movie";
import { all_series_list } from '../../actions/WebSeries/series';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
export default function SliderBanner() {
	const user=useSelector((state) => state.layout.profile)
	const dispatch = useDispatch();
	const location = useLocation();
	const [form, setForm] = useState({});
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);
	useMemo(() => {
		if(isEdit) 
     {setView("create_new") }
    else
    {setView("view_all")
    setForm({});
  }
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
	}, [location]);
	const [tableData, setTableData] = useState({
		tableTitle: "Slider Banner",
		deleteRecord: sliderbanner_delete,
		updateRecord: sliderbanner_update,
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "videoType",
				label: "Slider Type",
			},
			{
				id: "name",
				label: "Name",
			},
			
			{
				id: "app_banner",
				label: "App View",
				isImage: true,
			},
			
			
			{
				id: "set_sequence",
				label: "Sequence",
				align:"center"
			},
			{
				id: "expired_on",
				label: "Expired on",
				isSpecial: true,
				align:"left"
			},
			{
				id: "status",
				label: "Status",
			},
			{
				id: "edit",
				label: "Update",
			},
		],
		tableBody: [
			
		],
		filterColumn: [
			
			{
				id: "2",
			title: "Slider Type",
			name: "videoType",
			options: ["Video", "Series"],
			},
		
		],
	});

	const [formStructure, setFormStructure] = useState([
		{
			id: "1",
			type: "select",
			title: "Slider Type",
			name: "videoType",
			options: ["Video", "Series"],
			required:true
		},

		{
			id: "2",
			type: "select",
			title: "Select ",
			options: ["Movie1", "Movie2", "Movie3"],
			name: "movie_name",
			display: "none",
			required:true
		},
		
		{
			id: "4",
			type: "lockedInput",
			variant: "number",
			title: "Set Sequence",
			name: "set_sequence",
			min: "1",
			max: "99",
			required:true
		},
		{
			id: "4",
			type: "inputBox",
			variant: "date",
			title: "Expired on",
			min:new Date().toISOString().split('T')[0],
			name: "expiry_date",
			required:true
		},
		
		{
			id: "6",
			type: "image",
			title: "Browse App Banner",
			subtitle:"(Resolution : 1080px x 1080px) *",
        subsubtitle:"Max File Size 1MB",
		subsubsubtitle:"Support only JPG,PNG,JPEG",
			name: "app_banner",
			required:true
		},

		{
			id: "8",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	]);

	const sliderbanners = useSelector(
		(state) => state.merchandise.slider_banners
	);
	useEffect(() => {
		dispatch(all_sliderbanner_list());
	}, []);
	const movies = useSelector((state) => state.movies.movies);
	const series = useSelector((state) => state.webseries.series);
	useEffect(() => {
		const data=new FormData()
			data.append('id',user?.id)
		  
      dispatch(all_series_list(data))
		dispatch(all_movie_list(data));
	}, [user]);
	useMemo(() => {
		if (sliderbanners?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = sliderbanners?.data?.map((ele) => {
				if (ele?.movie != null)
					return {
						...ele,
						name: ele?.movie.movie_name,
						movie: ele?.movie.movie_name,
						expired_on:
					new Date(ele?.expiry_date) > new Date() ? (
						<p>{ele?.expiry_date}</p>
					) : (
						<p style={{ color: "red" }}>Expired</p>
					),
					};
				else if (ele?.series != null)
					return {
						...ele,
						name: ele?.series.series_name,
						series: ele?.series.series_name,
						expired_on:
					new Date(ele?.expiry_date) > new Date() ? (
						<p>{ele?.expiry_date}</p>
					) : (
						<p style={{ color: "red" }}>Expired</p>
					),
					};
					// else
					// return null
				
			}).filter(e=>e);

			setTableData({ ...temp });
			setForm({ ...form, set_sequence: tableData.tableBody.length + 1 });
		}
		console.log(sliderbanners)
	}, [sliderbanners]);

	const formTitle = isEdit ? "Edit Slider" : "Add Slider";

	useMemo(() => {
		if (form?.videoType == "Video") {
			const temp = formStructure;
			temp[1]["display"] = "block";
			temp[1]["title"] = "Select Video";
			temp[1]["name"] = "movie";
			temp[1]["options"] = movies?.data.map((ele) => ele.movie_name);
			setFormStructure([...temp]);
		} else if (form?.videoType == "Series") {
			const temp = formStructure;
			temp[1]["display"] = "block";
			temp[1]["title"] = "Select Series";
			temp[1]["name"] = "series";
			temp[1]["options"] = series?.data.map((ele)=>(ele.series_name));
			setFormStructure([...temp]);
		} 
		
		else {
			const temp = formStructure;
			temp[1]["display"] = "none";
			setFormStructure([...temp]);
		}
		
	}, [form?.videoType,form?.slider_type]);
	
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		const temp_form = form;
		temp_form["tempmovie"]=form?.["movie"]
			temp_form["tempseries"]=form?.["series"]
		temp_form["movie"] = movies?.data
			.map(
				(option) => form?.["movie"]?.includes(option.movie_name) && option.id
			)
			.filter((e) => e)[0];
			temp_form["series"] = series?.data
			.map(
				(option) => form?.["series"]?.includes(option.series_name) && option.id
			)
			.filter((e) => e)[0];
			
			
			
		setForm({ ...temp_form });
		
		Object.keys(form).map((key) => key!="expired_on"&&data.append(key, form?.[key]));
		
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp"+element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit) {
			
			dispatch(sliderbanner_update(data));
		} else {
			
			dispatch(sliderbanner_create(data));
		}
		
	};
	useMemo(()=>{
		if(message?.statuscode==200)
		{
			
		setIsEdit(false);
		setForm({});
		setTimeout(() => {
			dispatch(all_sliderbanner_list());
		}, 1000);
		setView("view_all");}
		else{
			const tempForm=form
			
			tempForm["movie"]=form?.tempmovie
			tempForm["series"]=form?.tempseries
			tempForm["web_banner"]=form?.tempweb_banner
			tempForm["app_banner"]=form?.tempapp_banner
			
		setForm({...tempForm });
		}
	},[message])
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
