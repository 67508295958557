import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
	movie_create,
	movie_delete,
	movie_update,
	all_movie_list,
} from "../../../actions/Movie/movie";
import { all_category_list } from "./../../../actions/Masters/category";
import { all_subcategory_list } from "../../../actions/Masters/subcategory";
import { all_cast_list } from "../../../actions/Masters/cast";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Export from "../../utils/Export";
import Import from "../../utils/Import";
import { useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";

export default function Movie() {
	const user = useSelector((state) => state.layout.profile);
	const dispatch = useDispatch();
	const location = useLocation();
	
	const [openAdError, setOpenAdError] = useState(false);
	
	const [content, setPopupContent] = useState("");
	const [view, setView] = useState("view_all");
	const [isEdit, setIsEdit] = useState(false);

	const [tableData, setTableData] = useState({
		...TableData(),
		deleteRecord: movie_delete,
		onDeleteText: "Are you sure want to delete?",
		customisedStatusUpdateMessage:true,
		onActiveText:"Are you sure want to Activate the video ?",
    onInactiveText:["Are you sure want to Deactivate the video ?"],
		updateRecord: movie_update,
	});

	const [form, setForm] = useState({});
	const tempFormStruct=FormStructure(isEdit)
	const [formStructure, setFormStructure] = useState([...tempFormStruct]);
	
	const [formTitle, setFormTitle] = useState(
		[
			"Video Details",
			"Video Resolutions"
			
		].filter((e) => e)
	);

	useEffect(() => {
		setView("view_all");
		setForm({
			set_position: tableData.tableBody.length + 1,
			movie_ownership: "Admin",
			subtitles: "Inactive",
			movie_is_for18Plus: "No",
			allow_download: "Yes",
		});
	}, [location]);

	const movies = useSelector((state) => state.movies.movies);
	useEffect(() => {
		const data = new FormData();
		data.append("id", user?.id);
		dispatch(all_movie_list(data));
	}, [user]);
	useMemo(() => {
		if (movies?.statuscode == 200) {
			const temp = tableData;
			// const dateObject = new Date(timestamp);
			
			temp.tableBody = movies?.data.map((value, index) => ({
				...value,
				
				movie_genre: value.movie_genre.map((genre) => genre.genre_title),
				movie_cast: value.movie_cast.map((cast) => cast.cast_name),
				create_at :  new Date(value?.created_at).toISOString().slice(0, 10)
				,
			

				
				color: value?.publish_type == "Later" && "#ebebeb",
			}));
			

			setTableData({ ...temp });
			setForm({ ...form, set_position: temp.tableBody.length + 1 });
		}
	}, [movies]);
	
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
			
		} else {
			setView("view_all");
			setForm({
				set_position: tableData.tableBody.length + 1,
				subtitles: "Inactive",
				movie_is_for18Plus: "No",
				allow_download: "Yes",
				movie_ownership: "Admin",
			});
		}
		
		const temp=formStructure
		tempFormStruct.map((arr,i)=>arr.map((ele,j)=>!["select","select_multiple"].includes(ele.type)&&(temp[i][j]=ele)))
		
		setFormStructure([...temp])
	}, [isEdit]);
	useMemo(() => {
		if (form?.publish_type == "Later") {
			const temp = formStructure;
			temp[0][8]["display"] = "block";
			temp[0][9]["display"] = "block";
			setFormStructure([...temp]);
		} else {
			const temp = formStructure;
			temp[0][8]["display"] = "none";
			temp[0][9]["display"] = "none";
			setFormStructure([...temp]);
		}
		if (form?.movie_access == "TVOD") {
			const temp = formStructure;
			temp[0][10]["display"] = "block";
			temp[0][11]["display"] = "block";
			temp[0][12]["display"] = "block";
			temp[0][13]["display"] = "block";
			temp[0][14]["display"] = "block";
			setFormStructure([...temp]);
		} else {
			const temp = formStructure;
			temp[0][10]["display"] = "none";
			temp[0][11]["display"] = "none";
			temp[0][12]["display"] = "none";
			temp[0][13]["display"] = "none";
			temp[0][14]["display"] = "none";
			setFormStructure([...temp]);
		}
		
		
		
	}, [form]);

	useMemo(() => {
		if (form?.rental_price != "") {
			setForm({
				...form,
				amount_received:
					form?.rental_price -
					form?.streaming_fees -
					Number(form?.rental_price) * 0.185,
				tax: Number(form?.rental_price) * 0.185,
			});
		}
	}, [form?.rental_price, form?.streaming_fees]);
	useMemo(() => {
		if (form?.amount_received < 0) {
			setOpenAdError(true);
			setPopupContent("Your receivable amount goes into Minus(-)");
		}
	}, [form?.amount_received]);

	useEffect(() => {
		
		dispatch(all_subcategory_list());
		dispatch(all_category_list());
		dispatch(all_cast_list());
		dispatch(all_genre_list());
		dispatch(all_language_list());
	}, [user,isEdit]);
	

	const categories = useSelector((state) => state.masters.categories);
	const subcategories = useSelector((state) => state.masters.subcategories);
	const casts = useSelector((state) => state.masters.casts);
	const genre = useSelector((state) => state.masters.genre);
	const language = useSelector((state) => state.masters.languages);
	useMemo(() => {
		if (categories?.statuscode == 200) {
			const temp = formStructure;
			temp[0][0]["options"] = categories?.data.map(
				(category) => category?.category_name
			);

			setFormStructure([...temp]);
			const tempFilter = tableData;
			tempFilter["filterColumn"][2]["options"] = categories?.data.map(
				(category) => category?.category_name
			);

			setTableData({ ...tempFilter });
		}
	}, [categories]);


	useMemo(() => {
		if (subcategories?.statuscode == 200) {
			const temp = formStructure;
			const tempForm = form;
			const tempFilter = tableData;
			tempFilter["filterColumn"][3]["options"] = subcategories?.data;

			if (form?.movie_category != undefined) {
				temp[0][1]["options"] = subcategories?.data
					.map(
						(subcategory) =>
							subcategory?.category_name == form?.movie_category &&
							subcategory?.subcategory_name
					)
					.filter((e) => e);
				tempForm.movie_subcategory = temp[0][1]["options"].includes(
					form?.movie_subcategory
				)
					? form?.movie_subcategory
					: "";
			} else {
				temp[0][1]["options"] = subcategories?.data.map(
					(subcategory) => subcategory?.subcategory_name
				);
			}
			setFormStructure([...temp]);
			setForm({ ...tempForm });
			setTableData({ ...tempFilter });
		}
	}, [subcategories, form?.movie_category]);
	useMemo(() => {
		if (casts?.statuscode == 200) {
			const temp = formStructure;
			temp[0][6]["options"] = casts?.data.map((cast) => cast?.cast_name);

			setFormStructure([...temp]);
		}
	}, [casts]);
	useMemo(() => {
		if (genre?.statuscode == 200) {
			const temp = formStructure;
			temp[0][5]["options"] = genre?.data.map((genre) => genre?.genre_title);

			setFormStructure([...temp]);
		}
	}, [genre]);
	useMemo(() => {
		if (language?.statuscode == 200) {
			const temp = formStructure;
			temp[0][4]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setFormStructure([...temp]);
			const tempFilter = tableData;
			tempFilter["filterColumn"][1]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setTableData({ ...tempFilter });
		}
	}, [language]);

	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		console.log(form,"85258")
		event.preventDefault();
		const data = new FormData();
	

		const temp_movie_category = form;
		temp_movie_category["tempmovie_subcategory"] = form?.["movie_subcategory"];
		temp_movie_category["tempmovie_category"] = form?.["movie_category"];
		temp_movie_category["tempmovie_cast"] = form?.["movie_cast"];
		temp_movie_category["tempmovie_genre"] = form?.["movie_genre"];
		temp_movie_category["tempmovie_language"] = form?.["movie_language"];
// 		temp_form["unit"] = Units?.data
//   ?.filter((option) => form?.["unit"] == option.unit_name)
//   .map((option) => option.id);
		temp_movie_category["movie_subcategory"] = subcategories?.data
		?.filter((option) => form?.["movie_subcategory"] == option.subcategory_name)
		.map((option) => option.id);
			// .map(
			// 	(option) =>
			// 		form?.["movie_subcategory"]?.includes(option.subcategory_name) &&
			// 		option.id
			// )
			// .filter((e) => e);
		temp_movie_category["movie_category"] = categories?.data
			.map(
				(option) =>
					form?.["movie_category"]?.includes(option.category_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_cast"] = casts?.data
			.map(
				(option) =>
					form?.["movie_cast"]?.includes(option.cast_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_genre"] = genre?.data
			.map(
				(option) =>
					form?.["movie_genre"]?.includes(option.genre_title) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_language"] = language?.data
			.map(
				(option) =>
					form?.["movie_language"]?.includes(option.language_name) && option.id
			)
			.filter((e) => e);
		

		
		temp_movie_category["created_by"] = user?.id;
		setForm({
			...temp_movie_category,
		});

		Object.keys(form).map((key) => data.append(key, form?.[key]));

		formStructure.map((structure) =>
			structure.map((element) => {
				if (
					element.type == "image" &&
					form?.[element.name] &&
					typeof form?.[element.name] != "string"
				) {
					const temp = form;
					temp["temp" + element.name] = form?.[element.name];
					temp[element.name] = URL.createObjectURL(form?.[element.name]);

					setForm({
						...temp,
					});
				}
			})
		);

		const temp = tableData;
		if (isEdit) {
			dispatch(movie_update(data));
		} else {
			dispatch(movie_create(data));
		}
	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			

			setIsEdit(false);

			setForm({
				set_position: tableData.tableBody.length + 1,
				movie_ownership:"Admin",
				subtitles: "Inactive",
				movie_is_for18Plus: "No",
				allow_download: "Yes",
			});
			setTimeout(() => {
				const data = new FormData();
				data.append("id", user?.id);
				dispatch(all_movie_list(data));
			}, 1000);
			setView("view_all");
		} else {
			const tempForm = form;
			tempForm["movie_subcategory"] = form?.["tempmovie_subcategory"];
			tempForm["movie_category"] = form?.["tempmovie_category"];
			tempForm["movie_cast"] = form?.["tempmovie_cast"];
			tempForm["movie_genre"] = form?.["tempmovie_genre"];
			tempForm["movie_language"] = form?.["tempmovie_language"];
			tempForm["movie_thumbnail"] = form?.["tempmovie_thumbnail"];
			tempForm["movie_poster"] = form?.["tempmovie_poster"];
			setForm({ ...tempForm });
		}
	}, [message]);
	const videoEl = useRef(null);
	const handleLoadedMetadata = () => {
		const video = videoEl.current;
		var d = Number(video.duration);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);
	
		var hDisplay = (h > 9 ? h : "0" + h) + ":";
		var mDisplay = (m > 9 ? m : "0" + m) + ":";
		var sDisplay = s > 9 ? s : "0" + s;
	
		
		setForm({ ...form, movie_duration: hDisplay + mDisplay + sDisplay });
	};

	const handleClose = () => {
		setOpenAdError(false);
	};
	

	

	return (
		<>
			<video
				style={{ display: "none" }}
				id="myvid"
				src={form?.movieMp4_URL}
				ref={videoEl}
				type="video/mp4"
				onLoadedMetadata={handleLoadedMetadata}
			></video>
			
			
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
		
				export_excel={
					<Export
						fileName={"Movies"}
						
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						headings={tableData.tableHead.map((value) => value.label)}
					/>
				}
				import_excel={
					<Import
						
						isClubed={true}
					/>
				}
			/>

			{view == "create_new" && (
				<MultiStepForm
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openAdError}
				autoHideDuration={1500}
				onClose={handleClose}
			>
				<Alert severity="info" variant="filled" color="success">
					{content}
				</Alert>
			</Snackbar>
		</>
	);
}
