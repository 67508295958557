import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
	upcoming_movie_create,
	upcoming_movie_delete,
	upcoming_movie_update,
	all_upcoming_movie_list,
} from "../../../actions/Movie/upcoming_movie";
import { useDispatch, useSelector } from "react-redux";
import { all_category_list } from "./../../../actions/Masters/category";
import { all_subcategory_list } from "../../../actions/Masters/subcategory";
import { all_cast_list } from "../../../actions/Masters/cast";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useLocation } from "react-router-dom";
export default function UpcomingMovie() {
	const user = useSelector((state) => state.layout.profile);
	const dispatch = useDispatch();
	const location = useLocation();
	const [view, setView] = useState("view_all");
	const [form, setForm] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const tempFormStruct=FormStructure(isEdit)
	const [formStructure, setFormStructure] = useState([...tempFormStruct]);
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({});
		}
		setFormStructure([...tempFormStruct])
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
	}, [location]);
	const [tableData, setTableData] = useState({
		...TableData(),
		deleteRecord: upcoming_movie_delete,
		updateRecord: upcoming_movie_update,
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
	});

	
	const formTitle = "Upcoming Videos";

	const upcoming_movies = useSelector((state) => state.movies.upcoming_movies);
	useEffect(() => {
		dispatch(all_upcoming_movie_list());
	}, []);
	useMemo(() => {
		if (upcoming_movies?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = upcoming_movies?.data.map((ele) => ({
				...ele,
				movie_category: ele?.movie_category?.category_name,
				movie_subcategory: ele?.movie_subcategory?.subcategory_name,
				movie_language: ele?.movie_language.language_name,
				movie_genre: ele?.movie_genre.map((value) => value?.genre_title),
				movie_cast: ele?.movie_cast.map((value) => value?.cast_name),
				release_date_display:
					new Date(ele?.release_date) > new Date() ? (
						<p>{ele?.release_date}</p>
					) : (
						<p style={{ color: "green" }}>RELEASED</p>
					),
			}));
			setTableData({ ...temp });
			
		}
	}, [upcoming_movies]);

	useEffect(() => {
		dispatch(all_subcategory_list());
		dispatch(all_category_list());
		dispatch(all_cast_list());
		dispatch(all_genre_list());

		dispatch(all_language_list());
	}, []);
	const categories = useSelector((state) => state.masters.categories);
	const subcategories = useSelector((state) => state.masters.subcategories);
	const casts = useSelector((state) => state.masters.casts);
	const genre = useSelector((state) => state.masters.genre);
	const language = useSelector((state) => state.masters.languages);
	useMemo(() => {
		if (categories?.statuscode == 200) {
			const tempTableData = tableData;
			tempTableData.filterColumn[1]["options"] = categories?.data.map(
				(category) => category?.category_name
			);

			setTableData({ ...tempTableData });
		}
		if (categories?.statuscode == 200) {
			const temp = formStructure;
			temp[0]["options"] = categories?.data.map(
				(category) => category?.category_name
			);

			setFormStructure([...temp]);
		}
	}, [categories]);
	useMemo(() => {
		if (subcategories?.statuscode == 200) {
			const temp = formStructure;
			const tempForm = form;
			

			if (form?.movie_category != undefined) {
				temp[1]["options"] = subcategories?.data
					.map(
						(subcategory) =>
							subcategory?.category_name == form?.movie_category &&
							subcategory?.subcategory_name
					)
					.filter((e) => e);
				tempForm.movie_subcategory = temp[1]["options"].includes(
					form?.movie_subcategory
				)
					? form?.movie_subcategory
					: "";
			} else {
				temp[1]["options"] = subcategories?.data.map(
					(subcategory) => subcategory?.subcategory_name
				);
			}
			setFormStructure([...temp]);
			setForm({ ...tempForm });
			
		}
	}, [subcategories, form?.movie_category]);
	useMemo(() => {
		if (casts?.statuscode == 200) {
			const temp = formStructure;
			temp[6]["options"] = casts?.data.map((cast) => cast?.cast_name);

			setFormStructure([...temp]);
		}
	}, [casts]);
	useMemo(() => {
		if (genre?.statuscode == 200) {
			const temp = formStructure;
			temp[4]["options"] = genre?.data.map((genre) => genre?.genre_title);

			setFormStructure([...temp]);
		}
	}, [genre]);
	useMemo(() => {
		if (language?.statuscode == 200) {
			const tempTableData = tableData;
			tempTableData.filterColumn[0]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setTableData({ ...tempTableData });
			const temp = formStructure;
			temp[3]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setFormStructure([...temp]);
		}
	}, [language]);
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const temp_movie_category = form;
		temp_movie_category["movie_subcategory"] = subcategories?.data
			.map(
				(option) =>
					form?.["movie_subcategory"]?.includes(option.subcategory_name) &&
					option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_category"] = categories?.data
			.map(
				(option) =>
					form?.["movie_category"]?.includes(option.category_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_cast"] = casts?.data
			.map(
				(option) =>
					form?.["movie_cast"]?.includes(option.cast_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_genre"] = genre?.data
			.map(
				(option) =>
					form?.["movie_genre"]?.includes(option.genre_title) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_language"] = language?.data
			.map(
				(option) =>
					form?.["movie_language"]?.includes(option.language_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["tempmovie_subcategory"] = form?.["movie_subcategory"];
		temp_movie_category["tempmovie_category"] = form?.["movie_category"];
		temp_movie_category["tempmovie_cast"] = form?.["movie_cast"];
		temp_movie_category["tempmovie_genre"] = form?.["movie_genre"];
		temp_movie_category["tempmovie_language"] = form?.["movie_language"];

		temp_movie_category["created_by"] = user?.id;
		setForm({
			...temp_movie_category,
		});
		
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp" + element.name] = URL.createObjectURL(form?.[element.name]);
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit) {
			dispatch(upcoming_movie_update(data));
		} else {
			dispatch(upcoming_movie_create(data));
		}
	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			

			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_upcoming_movie_list());
			}, 1000);
			setView("view_all");
		} else {
			const tempForm = form;

			tempForm["movie_subcategory"] = form?.tempmovie_subcategory;
			tempForm["movie_category"] = form?.tempmovie_category;
			tempForm["movie_cast"] = form?.tempmovie_cast;
			tempForm["movie_genre"] = form?.tempmovie_genre;
			tempForm["movie_language"] = form?.tempmovie_language;
			tempForm["movie_thumbnail"] = form?.tempmovie_thumbnail;
			tempForm["movie_poster"] = form?.tempmovie_poster;
			setForm({ ...tempForm });
		}
	}, [message]);
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			)}
		</>
	);
}
