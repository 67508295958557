import { combineReducers } from 'redux';
import masters from './masters';
import subscriptions from './subscriptions'
import customers from './customers'
import merchandise from './merchandise'
import movies from './movies'
import transactions from "./transactions"
import watchhours from "./watchhours"
import webseries from './webseries';
import setting from './setting'
import dashboard from './dashboard'
import layout from "./layout"
import complaints from "./complaints"
export const reducers = combineReducers({ masters,  complaints,  layout, dashboard, setting, subscriptions, customers,  merchandise, movies,  transactions, watchhours, webseries });
