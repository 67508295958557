

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Movie from "../components/Movie/Movie/Movie"
import UpcomingMovie from '../components/Movie/UpcomingMovie/UpcomingMovie';
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
function MovieModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Movie' element={<Layout children={Movie()}/>}/>
< Route path='/UpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/>


< Route path='/MovieDetails' element={<Layout children={ProductDetails()}/>}/>
      </Routes>
    </div>
  );
}

export default MovieModule;
