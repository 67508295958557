

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Series from "../components/WebSeries/Series/Series"
import Episode from '../components/WebSeries/Episode/Episode';
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
function WebSeriesModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Series' element={<Layout children={Series()}/>}/>

< Route path='/Episode' element={<Layout children={Episode()}/>}/>

< Route path='/Episode/EpisodeDetails' element={<Layout children={ProductDetails()}/>}/>
      </Routes>
    </div>
  );
}

export default WebSeriesModule;
