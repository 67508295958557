import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SliderBanner from "../components/SliderBanner/SliderBanner"
// import Subscriptions from '../components/Subscription/Subscriptions';
function SliderBannerModule() {
  return (
    <div className="App">
    
<Routes>
{/* < Route path='/SliderBanner' element={<Layout children={Subscriptions()}/>}/> */}
< Route path='/SliderBanner' element={<Layout children={SliderBanner()}/>}/>


      </Routes>
    </div>
  );
}

export default SliderBannerModule;
