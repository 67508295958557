import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import { notification_create, notification_delete, notification_update, all_notification_list, notification_read_status_update } from '../../actions/notification';
import { active_user_list } from '../../actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EnlargedView from "../utils/EnlargedView";
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
export default function Notification() {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.layout.profile)
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState();
	const handleOpen = () => setOpen(true);
	const location = useLocation();
	const [form, setForm] = useState({});
	const [view, setView] = useState("view_all");
	const [open1, setOpen1] = useState(false);

	const [isEdit, setIsEdit] = useState(false);
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	useEffect(() => {
		setView("view_all");
		setForm({});
		setIsEdit(false);
	}, [location]);
	const [tableData, setTableData] = useState({
		tableTitle: "Notification", deleteRecord: notification_delete,
		updateRecord: notification_update,
		openModal: handleOpen,
		onDeleteText: "Are you sure want to delete the Notification?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "notification_title",
				label: "Notification Title",
				// isSpecial: true
			},
			{
				id: "created_at",
				label: "Sent On",
			},
			{
				id: "notification_image",
				label: "Image",
				isImage: true,
			}
			,
			{
				id: "description",
				label: "Description",
				isModal: true,
				default: <><button style={{ padding: "5px 15px", color: "rgb(238, 127, 37)", background: "transparent", border: "1px solid rgb(238, 127, 37)", borderRadius: "5px" }}>VIEW</button></>
			},
			{
				id: "customers",
				label: "customers",
				isModal: true,
				default: <><button style={{ padding: "5px 15px", color: "rgb(238, 127, 37)", background: "transparent", border: "1px solid rgb(238, 127, 37)", borderRadius: "5px" }}>VIEW</button></>

			},
		].filter(e => e),
		tableBody: [

		],
	});
	const [formStructure, setFormStructure] = useState([
		{
			id: "1",
			type: "inputBox",
			title: "Notification Title",
			name: "notification_title",
			required: true

		},
		{
			id: "2",
			type: "select_multiple",
			title: "Select User",
			name: "customers",
			options: ["By Plan", "By Movie"],
			required: true

		},

		{
			id: "3",
			type: "inputBox",
			title: "Description",
			name: "description",
			maxLength: "100",
			required: true

		}
		,

		{
			id: "4",
			type: "image",
			title: "Notification Image",
			name: "notification_image",
			subtitle: "(Resolution :1920px * 1080px) *",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			// required: true
		},
		{
			id: "5",
			type: "button",
			title: "Send",
		},
	]);



	const notifications = useSelector((state) => state.merchandise.notifications);
		// const customers = useSelector((state) => state.customers.customers);
	const customers = useSelector((state) => state?.customers?.actcustomer);
	// console.log(notifications, "nooooo")

	useEffect(() => {
		// dispatch(all_customer_list())
		dispatch(active_user_list())
	}, [])
	useMemo(() => {
		if (customers?.statuscode == 200) {
			const temp = formStructure
			const tempArray = ["All Users"]
			customers?.data.map((ele) => tempArray.push(ele.firstName + " " + ele.lastName))
			temp[1]["options"] = tempArray
			setFormStructure([...temp])
		}
	}, [customers])
	useEffect(() => {
		const data = new FormData()
		data.append('id', user?.id)
		dispatch(all_notification_list(data))
	}, [user])
	useMemo(() => {

		if (notifications?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = notifications?.data.map((ele) => ({
				...ele,
				customers: ele?.customers.length !== customers?.data.length ?
					ele?.customers?.map((ele) =>
					(<><p>{(ele.firstName + " " + ele.lastName)}</p>
						<p>{temp?.tableHead?.default}</p> </>)) :

					<><p>{"All Customer"}</p>
						<p>{temp?.tableHead?.default}</p> </>,
				// notification_title_display: (
				// 	<p style={{ textAlign: "left", fontWeight: ele?.notification_read == "false" ? "600" : "400", cursor: "pointer" }}
				// 		onClick={() => {
				// 			const data = new FormData()
				// 			data.append('id', ele?.id)
				// 			if (ele?.notification_read == "false") {
				// 				dispatch(notification_read_status_update(data))
				// 				setTimeout(() => {
				// 					const data = new FormData()
				// 					data.append('id', user?.id)
				// 					dispatch(all_notification_list(data));
				// 				}, 1000);
				// 			}
				// 			setContent(
				// 				<Card sx={{ border: "none", boxShadow: "none", minWidth: "25vw" }}>
				// 					<CardHeader

				// 						title={ele?.notification_title}
				// 						subheader={ele?.created_at}
				// 					/>
				// 					{ele?.notification_image && <CardMedia
				// 						component="img"
				// 						image={ele?.notification_image}
				// 					/>}
				// 					<CardContent>
				// 						<Typography variant="body2" color="text.secondary">
				// 							<div dangerouslySetInnerHTML={{ __html: ele?.description }} />
				// 							{/* {console.log(typeof(ele?.description))}fd */}

				// 						</Typography>
				// 					</CardContent>


				// 				</Card>
				// 			);
				// 			handleOpen();
				// 		}}
				// 	>{ele?.notification_title}</p>

				// ),
				description:
					<><div dangerouslySetInnerHTML={{ __html: ele?.description }} />
						<p>{temp?.tableHead?.default}</p> </>,
			}))


			setTableData({ ...temp })
		}
		console.log(tableData, "00000000")
	}, [notifications])

	const formTitle = isEdit ? "Edit Notification" : "Create Notification";

	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		// if (!form["description"]) {
		// 	const fffff = formStructure
		// 	fffff[2]["display"] = true
		// 	setFormStructure([...fffff])
		// 	// setFormStructure({formStructure[2]["display"] : true})
		// 	return;
		// }
		// else {
		setOpen1(true)
		const tempForm = form
		tempForm["tempcustomers"] = form?.["customers"]

		if (tempForm["customers"] == "All Users") {
			tempForm["customers"] = customers?.data.map((option) => option.id).filter(e => e)
		}
		else {


			tempForm["customers"] = customers?.data.map((option) => form?.["customers"]?.includes(option.firstName + " " + option.lastName) && option.id).filter(e => e)
		}
		setForm({
			...tempForm
		});

		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		const tempForm1 = form
		tempForm1["customers"] = form?.["tempcustomers"]
		setForm({
			...tempForm1
		});
		// console.log(form, 'ADitya ka code')

		if (isEdit) {
			dispatch(notification_update(data))
		}
		else {
			dispatch(notification_create(data))
		}
		// }

	};
	useMemo(() => {
		if (message?.statuscode == 200) {

			setIsEdit(false);
			setForm({});
			setOpen1(false)
			setTimeout(() => {
				const data = new FormData()
				data.append('id', user?.id)
				dispatch(all_notification_list(data));
			}, 1000);
			setView("view_all");
		}
		else {

			setForm({ ...form });
		}
	}, [message])
	// useEffect(() => {
	// 	if (notifications?.statuscode !== 200) {
	// 		setOpen1(true)
	// 	}
	// 	else {
	// 		setOpen1(false)
	// 	}
	// }, [notifications])
	return (
		<>
	<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 ,
				 display: 'flex',
				alignItems: 'center',
				gap:"1rem",
				flexDirection: 'column', }}

				open={open1}
			>
				<CircularProgress color="inherit" />
				<p>Due to High Consumption of our users , it will take time time to send Notifications
It will take Few Min</p>
			</Backdrop>
			<EnlargedView open={open} setOpen={setOpen} content={content} />
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					setContent={setContent}
				/>
			)}
		</>
	);
}
